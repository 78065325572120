import { JobStatus } from "../enums/job.enum";

export const JOBS_FEATURE_KEY = "jobs";
export const JOBS_PAGE_SIZE = 50;

export const NEW_JOB_STATE = {
	id: -1,
	name: "",
	assignedTo: "",
	closed: "",
	startLocation: "",
	endLocation: "",
	jobTypeId: -1,
	customerId: -1,
	price: 0,
	profitPercent: 0,
	purchaseOrderNumber: "",
	status: JobStatus.NEW,
	notes: "",
	goodsToSave: [],
};

export const JOB_STATUSES = [
	{
		id: JobStatus.NEW,
		name: "New",
	},
	{
		id: JobStatus.PLANNED,
		name: "Planned",
	},
	{
		id: JobStatus.LOADED,
		name: "Loaded",
	},
	{
		id: JobStatus.COMPLETED,
		name: "Completed",
	},
	{
		id: JobStatus.PAUSED,
		name: "Paused",
	},
	{
		id: JobStatus.CANCELLED,
		name: "Cancelled",
	},
	{
		id: JobStatus.ARCHIVED,
		name: "Archived",
	},
];

export const JOB_STATUS_MAPPINGS: {
	[key: number]: { title: string; className: string };
} = {
	1: {
		title: "New",
		className: "gray",
	},
	2: {
		title: "Planned",
		className: "yellow",
	},
	3: {
		title: "Loaded",
		className: "darkGreen",
	},
	4: {
		title: "Completed",
		className: "green",
	},
	5: {
		title: "Paused",
		className: "violet",
	},
	6: {
		title: "Cancelled",
		className: "red",
	},
	7: {
		title: "Archived",
		className: "gray",
	},
	8: {
		title: "Unchecked",
		className: "blue",
	},
	9: {
		title: "Checked",
		className: "green",
	},
	10: {
		title: "Invoice generated",
		className: "green",
	},
	11: {
		title: "Invoice sent",
		className: "blue",
	},
	12: {
		title: "Invoice failed",
		className: "red",
	},
	13: {
		title: "Ready for re-invoice",
		className: "yellow",
	},
	14: {
		title: "Checked again",
		className: "green",
	},
	15: {
		title: "Re-invoice generated",
		className: "green",
	},
	16: {
		title: "Re-invoice sent",
		className: "green",
	},
	17: {
		title: "Scheduled for invoice",
		className: "yellow",
	},
};
