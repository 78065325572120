import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	ILoadTypeRequestDto,
	ILoadTypeResponseDto,
} from "../../../../common/models/src/lib/interfaces/load-type.interface";
import { jobApi } from "../../../../common/utils/src/lib/axios.utils";
import { buildQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";

export const fetchLoadTypes = async (params: CelerumQueryParams) => {
	const query = buildQueryString(params);

	const response = await jobApi.get<CelerumResponse>(`/LoadType${query}`);
	return response.data;
};

export const createLoadType = async (loadType: ILoadTypeRequestDto) => {
	const response = await jobApi.post<ILoadTypeResponseDto>(
		"/LoadType",
		loadType,
	);
	return response.data;
};

export const updateLoadType = async (loadType: ILoadTypeRequestDto) => {
	const response = await jobApi.put<ILoadTypeResponseDto>(
		"/LoadType",
		loadType,
	);
	return response.data;
};

export const deleteLoadType = async (id: number) => {
	await jobApi.delete<number>(`/LoadType/${id}`);
	return id;
};
