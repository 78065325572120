import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	ITrailerRequestDto,
	ITrailerResponseDto,
} from "../../../../common/models/src/lib/interfaces/trailer.interface";
import { jobApi } from "../../../../common/utils/src/lib/axios.utils";
import { buildQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";

export const fetchTrailers = async (params: CelerumQueryParams) => {
	const { page, pageSize } = params;
	const query = buildQueryString(params);

	const response = await jobApi.get<CelerumResponse>(`/Trailer${query}`);

	return {
		...response.data,
		page,
		pageSize,
	};
};

export const createTrailer = async (trailer: ITrailerRequestDto) => {
	const response = await jobApi.post<ITrailerResponseDto>("/Trailer", trailer);
	return response.data;
};

export const updateTrailer = async (trailer: ITrailerRequestDto) => {
	const response = await jobApi.put<ITrailerResponseDto>("/Trailer", trailer);
	return response.data;
};

export const deleteTrailer = async (id: number) => {
	await jobApi.delete(`/Trailer/${id}`);
	return id;
};
