import { SvgIcon } from "@progress/kendo-react-common";
import { Dialog } from "@progress/kendo-react-dialogs";
import type { ReactNode } from "react";
import { closeButtonIcon } from "../../../../../models/src/lib/constants/icon.constants";
import styles from "./celerum-modal.module.scss";

interface CelerumModalProps {
	visible: boolean;
	width: number;
	toggleDialog?: () => void;
	children: ReactNode;
	title?: string;
	height?: number;
}

export const CelerumModal = ({
	title,
	width,
	height,
	children,
	toggleDialog,
	visible,
}: CelerumModalProps) => (
	<div>
		{visible && (
			<Dialog onClose={toggleDialog} width={width} height={height}>
				<div className={styles.header}>
					<span className={styles.header__title}>{title}</span>
					<SvgIcon
						width={25}
						className={styles.header__icon}
						icon={closeButtonIcon}
						onClick={(event) => {
							event.stopPropagation();
							toggleDialog?.();
						}}
					/>
				</div>
				{children}
			</Dialog>
		)}
	</div>
);
