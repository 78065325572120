import { useNavigate } from "react-router-dom";
import { CelerumActions } from "../../../../../common/models/src/lib/enums/actions.enum";
import { JobStatus } from "../../../../../common/models/src/lib/enums/job.enum";
import { useAppDispatchWithNotifications } from "../../../../../common/stores/src/lib/utils";
import {
	changeInvoiceStatusAction,
	getPreviewAction,
	markInvoiceableJobAsCheckedAction,
	markInvoiceableJobAsSentAction,
	reInvoiceJobAction,
	sendInvoiceableJobToReviewAction,
} from "../../../../data-access/src/lib/invoices.slice";

export const useInvoiceableJobActionSelected = () => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const navigate = useNavigate();

	return async (
		action: CelerumActions | null,
		id: number | null,
		status?: JobStatus,
	) => {
		if (!id || !action) {
			return;
		}

		switch (action) {
			case +CelerumActions["Send for Review"]: {
				await dispatchWithNotifications({
					action: sendInvoiceableJobToReviewAction,
					payload: id,
					errorMessage: "Could not send job to review.",
					successMessage: "Successfully sent job back for review.",
				});
				break;
			}
			case +CelerumActions["Preview Invoice"]: {
				const result = await dispatchWithNotifications({
					action: getPreviewAction,
					payload: id,
				});
				if (getPreviewAction.fulfilled.match(result)) {
					const data = result.payload;
					window.open(URL.createObjectURL(data));
				}
				break;
			}
			case +CelerumActions["Mark as Checked"]: {
				dispatchWithNotifications({
					action: markInvoiceableJobAsCheckedAction,
					payload: { id, status },
					errorMessage: "Could not mark job as checked.",
				});
				break;
			}
			case +CelerumActions["Mark as Sent"]: {
				if (status === JobStatus.INVOICE_GENERATED) {
					dispatchWithNotifications({
						action: markInvoiceableJobAsSentAction,
						payload: { id, status: JobStatus.INVOICE_SENT },
						errorMessage: "Could not mark job as sent.",
					});
				}

				if (status === JobStatus.REINVOICE_GENERATED) {
					dispatchWithNotifications({
						action: markInvoiceableJobAsSentAction,
						payload: { id, status: JobStatus.REINVOICE_SENT },
						errorMessage: "Could not mark job as sent.",
					});
				}

				break;
			}
			case +CelerumActions["Re-invoice"]: {
				dispatchWithNotifications({
					action: reInvoiceJobAction,
					payload: id,
					errorMessage: "Could not re-invoice job.",
				});
				break;
			}
			case +CelerumActions["Send to Operations"]: {
				dispatchWithNotifications({
					action: sendInvoiceableJobToReviewAction,
					payload: id,
					errorMessage: "Could not send job to operations.",
				});
				break;
			}
			case +CelerumActions["Send to Invoicing Check"]: {
				dispatchWithNotifications({
					action: changeInvoiceStatusAction,
					payload: { id, status: JobStatus.READY_FOR_INVOICE },
					errorMessage: "Could not send job to invoicing check.",
				});
				break;
			}
			case +CelerumActions.Edit: {
				navigate(`/jobs/${id}`, { state: { from: window.location.pathname } });
				break;
			}
		}
	};
};
