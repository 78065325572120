import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import { BUSINESS_UNITS_FEATURE_KEY } from "../../../../common/models/src/lib/constants/business-unit.constants";
import type {
	BusinessUnitState,
	IBusinessUnit,
} from "../../../../common/models/src/lib/interfaces/business-unit.interface";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import { fetchBusinessUnits } from "./business-units-data-access";

const initialState: BusinessUnitState = {
	data: [],
	total: 0,
	aggregateResults: null,
	loading: false,
	errors: null,
};

export const fetchBusinessUnitsAction = createAsyncThunk(
	"businessUnit/fetchBusinessUnits",
	(params: CelerumQueryParams = {}) => {
		return fetchBusinessUnits(params);
	},
);

const businessUnitsSlice = createSlice({
	name: BUSINESS_UNITS_FEATURE_KEY,
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchBusinessUnitsAction.pending, (state: BusinessUnitState) => {
				state.loading = true;
			})
			/** Fulfilled */
			.addCase(
				fetchBusinessUnitsAction.fulfilled,
				(state: BusinessUnitState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults } = action.payload;
					state.data = data as IBusinessUnit[];
					state.total = total;
					state.aggregateResults = aggregateResults;
					state.loading = false;
				},
			)
			/** Rejected */
			.addCase(
				fetchBusinessUnitsAction.rejected,
				(state: BusinessUnitState) => {
					state.loading = false;
				},
			);
	},
});

/** Reducer */
export const businessUnitsReducer = businessUnitsSlice.reducer;
