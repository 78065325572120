import { CURRENCY } from "../../../../models/src/lib/constants/currencies.constants";

export const getCurrencySymbol = (
	currency: string | undefined | null,
): string => {
	switch (currency) {
		case "EUR":
			return "€";
		case "GBP":
			return "£";
		case "USD":
			return "$";
		default:
			return "";
	}
};

export const getFormattedCurrency = (
	value: number,
	currency = "USD",
	maxFractionDigits = 2,
) => {
	return getNumberFormat(currency, 0, maxFractionDigits, "currency").format(
		value,
	);
};

export const getFormattedValue = (
	value: number,
	currency = "USD",
	minFractionDigits = 0,
	maxFractionDigits = 2,
) => {
	return getNumberFormat(currency, minFractionDigits, maxFractionDigits).format(
		value,
	);
};

const getNumberFormat = (
	currency = "USD",
	minFractionDigits = 0,
	maxFractionDigits = 2,
	style: Intl.NumberFormatOptionsStyle = "decimal",
) => {
	switch (currency) {
		case CURRENCY.USD:
			return Intl.NumberFormat("en-US", {
				style: style,
				currency: CURRENCY.USD,
				minimumFractionDigits: minFractionDigits,
				maximumFractionDigits: maxFractionDigits,
			});
		case CURRENCY.EUR:
			return Intl.NumberFormat("de-DE", {
				style: style,
				currency: CURRENCY.EUR,
				minimumFractionDigits: minFractionDigits,
				maximumFractionDigits: maxFractionDigits,
			});
		case CURRENCY.GBP:
			return Intl.NumberFormat("en-GB", {
				style: style,
				currency: CURRENCY.GBP,
				minimumFractionDigits: minFractionDigits,
				maximumFractionDigits: maxFractionDigits,
			});
		default:
			return Intl.NumberFormat("en-US", {
				style: style,
				currency: CURRENCY.USD,
				minimumFractionDigits: minFractionDigits,
				maximumFractionDigits: maxFractionDigits,
			});
	}
};
