import { JobStatus } from "../../../../../common/models/src/lib/enums/job.enum";
import type { IInvoiceableJob } from "../../../../../common/models/src/lib/interfaces/invoice.interface";

export const canPreviewInvoice = () => true;

export const canReInvoice = (item: unknown) => {
	const invoiceableJob = item as IInvoiceableJob;
	return (
		invoiceableJob.status === JobStatus.INVOICE_GENERATED ||
		invoiceableJob.status === JobStatus.INVOICE_SENT ||
		invoiceableJob.status === JobStatus.REINVOICE_GENERATED ||
		invoiceableJob.status === JobStatus.REINVOICE_SENT
	);
};

export const canMarkAsSent = (item: unknown) => {
	const invoiceableJob = item as IInvoiceableJob;
	return (
		invoiceableJob.status === JobStatus.INVOICE_GENERATED ||
		invoiceableJob.status === JobStatus.REINVOICE_GENERATED
	);
};

export const canSendToOperations = (item: unknown) => {
	const invoiceableJob = item as IInvoiceableJob;
	return (
		invoiceableJob.status === JobStatus.READY_FOR_INVOICE ||
		invoiceableJob.status === JobStatus.READY_FOR_REINVOICE
	);
};

export const canSendToInvoicingCheck = (item: unknown) => {
	const invoiceableJob = item as IInvoiceableJob;
	return invoiceableJob.status === JobStatus.CHECKED;
};

export const canEditInvoice = () => {
	return true;
};
