export const DRIVERS_FEATURE_KEY = "drivers";
export const DRIVERS_PAGE_SIZE = 50;

export const NEW_DRIVER_STATE = {
	id: -1,
	firstName: "",
	lastName: "",
	email: "",
	phoneNumber: "",
	licenceExpirationDate: new Date().toDateString(),
	notes: "",
	qualifications: [],
	constraints: [],
	status: 0,
};
