import {
	accountantRoutes,
	adminRoutes,
	operatorRoutes,
	superAdminRoutes,
} from "../../../../models/src/lib/constants/route.constants";
import {
	ACCOUNTANT_ROLE,
	ADMIN_ROLE,
	OPERATOR_ROLE,
	SUPER_ADMIN_ROLE,
} from "../../../../models/src/lib/constants/user.constants";

export const getRoutesBasedOnRole = (userRoleName: string | undefined) => {
	switch (userRoleName) {
		case ADMIN_ROLE: {
			return adminRoutes;
		}
		case OPERATOR_ROLE: {
			return operatorRoutes;
		}
		case ACCOUNTANT_ROLE: {
			return accountantRoutes;
		}
		case SUPER_ADMIN_ROLE: {
			return superAdminRoutes;
		}
		default:
			return [];
	}
};
