import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	ILoad,
	ILoadDetails,
	ILoadRequestDto,
} from "../../../../common/models/src/lib/interfaces/load.interface";
import { jobApi } from "../../../../common/utils/src/lib/axios.utils";
import { buildQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";

export const fetchLoads = async (params: CelerumQueryParams) => {
	const { page, pageSize } = params;
	const query = buildQueryString(params);

	const response = await jobApi.get<CelerumResponse>(`/Load${query}`);

	return {
		...response.data,
		page,
		pageSize,
	};
};

export const fetchLoadById = async (id: number) => {
	const response = await jobApi.get(`Load/${id}`);
	return {
		...response.data,
		assignedUser: response.data.assignedUser,
		createdByUser: response.data.createdByUser,
	};
};

export const createLoad = async (load: ILoadRequestDto) => {
	const response = await jobApi.post<ILoad>("/Load", load);
	return response.data;
};

export const updateLoad = async (load: ILoadRequestDto) => {
	const response = await jobApi.put<ILoadDetails>("/Load", load);
	return response.data;
};

export const deleteLoad = async (id: number) => {
	await jobApi.delete(`/Load/${id}`);
	return id;
};

export const pauseLoad = async (id: number) => {
	await jobApi.post(`/Load/${id}/change-status/pause`, null);
	return id;
};

export const cancelLoad = async (id: number) => {
	await jobApi.post(`/Load/${id}/change-status/cancel`, null);
	return id;
};

export const resumeLoad = async (id: number) => {
	await jobApi.post(`/Load/${id}/change-status/resume`, null);
	return id;
};

export const generateManifest = async (id: number) => {
	const response = await jobApi.get<Blob>(`Load/report/${id}/manifest`, {
		responseType: "blob",
	});
	return response.data;
};

export const generatePrintAllDocuments = async (id: number) => {
	const response = await jobApi.get<Blob>(`Load/report/${id}/all-documents`, {
		responseType: "blob",
	});
	return response.data;
};

export const getClearCustomsAttachments = async (id: number) => {
	const response = await jobApi.get(`Load/${id}/clearCustomsAttachments`);
	return response.data;
};
