import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	IDriver,
	IDriverRequestDto,
} from "../../../../common/models/src/lib/interfaces/driver.interface";
import type {
	IQualification,
	IQualificationRequestDto,
} from "../../../../common/models/src/lib/interfaces/qualification.interface";
import {
	authenticationApi,
	jobApi,
} from "../../../../common/utils/src/lib/axios.utils";
import { buildQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";

export const fetchDrivers = async (params: CelerumQueryParams) => {
	const { page, pageSize } = params;
	const query = buildQueryString(params);

	const response = await jobApi.get<CelerumResponse>(`/Driver${query}`);
	return {
		...response.data,
		page,
		pageSize,
	};
};

export const fetchDriverById = async (id: number) => {
	const response = await jobApi.get<IDriver>(`/Driver/${id}`);
	return response.data;
};

export const createDriver = async (driver: IDriverRequestDto) => {
	const response = await jobApi.post<IDriver>("/Driver", driver);
	return response.data;
};

export const updateDriver = async (driver: IDriverRequestDto) => {
	const response = await jobApi.put<IDriver>("/Driver", driver);
	return response.data;
};

export const deleteDriver = async (id: number) => {
	await jobApi.delete(`/Driver/${id}`);
	return id;
};

export const fetchQualificationsByDriverId = async (id: number) => {
	const response = await jobApi.get<IQualification[]>(
		`/Qualification/driver/${id}`,
	);
	return response.data;
};

export const createQualification = async (
	qualification: IQualificationRequestDto,
) => {
	const response = await jobApi.post<IQualification>(
		"/Qualification",
		qualification,
	);
	return response.data;
};

export const updateQualification = async (
	qualification: IQualificationRequestDto,
) => {
	const response = await jobApi.put<IQualification>(
		"/Qualification",
		qualification,
	);
	return response.data;
};

export const deleteQualification = async (id: number) => {
	await jobApi.delete(`/Qualification/${id}`);
	return id;
};

export const fetchNotLinkedDrivers = async (userId: string) => {
	const response = await authenticationApi.get(`User/${userId}/driver-info`);
	return response.data;
};
