import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import { ORGANISATIONS_FEATURE_KEY } from "../../../../common/models/src/lib/constants/organisation.constants";
import type {
	IOrganisation,
	OrganisationState,
} from "../../../../common/models/src/lib/interfaces/organisation.interface";
import { fetchOrganisationById } from "./organisations-data-access";

export const fetchOrganisationByIdAction = createAsyncThunk(
	"organisation/fetchOrganisationById",
	(id: string) => {
		return fetchOrganisationById(id);
	},
);

const initialState: OrganisationState = {
	organisation: null,
	total: 0,
	aggregateResults: null,
	loading: false,
	errors: null,
	data: [],
};

const organisationsSlice = createSlice({
	name: ORGANISATIONS_FEATURE_KEY,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(
				fetchOrganisationByIdAction.pending,
				(state: OrganisationState) => {
					state.loading = true;
				},
			)
			/** Fulfilled */
			.addCase(
				fetchOrganisationByIdAction.fulfilled,
				(state: OrganisationState, action: PayloadAction<IOrganisation>) => {
					const organisation = action.payload;
					state.organisation = organisation;
					state.loading = false;
				},
			)
			/** Rejected */
			.addCase(
				fetchOrganisationByIdAction.rejected,
				(state: OrganisationState) => {
					state.loading = false;
				},
			);
	},
});

/** Reducer */
export const organisationsReducer = organisationsSlice.reducer;
