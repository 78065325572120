import { Zoom } from "@progress/kendo-react-animation";
import { NotificationGroup } from "@progress/kendo-react-notification";
import { NotificationType } from "../../../../../models/src/lib/enums/notification-type.enum";
import { CelerumNotification } from "../celerum-notification/celerum-notification.component";

interface CelerumNotificationsGroupProps {
	type: NotificationType | null;
	message: string;
	onClose?: () => void;
}

export const CelerumNotificationsGroup = ({
	message,
	type,
}: CelerumNotificationsGroupProps) => {
	const renderNotification = () => {
		switch (type) {
			case NotificationType.Success:
				return <CelerumNotification type="success" message={message} />;
			case NotificationType.Error:
				return <CelerumNotification type="error" message={message} />;
			case NotificationType.Warning:
				return <CelerumNotification type="warning" message={message} />;
			case NotificationType.Info:
				return <CelerumNotification type="info" message={message} />;
			default:
				return null;
		}
	};

	return (
		<NotificationGroup
			style={{
				right: 20,
				bottom: 20,
				alignItems: "flex-end",
				flexWrap: "wrap-reverse",
			}}
		>
			<Zoom>{renderNotification()}</Zoom>
		</NotificationGroup>
	);
};
