import {
	Field,
	Form,
	FormElement,
	type FormRenderProps,
} from "@progress/kendo-react-form";
import cls from "classnames";
import type { ComponentProps } from "react";
import { Link } from "react-router-dom";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import {
	CelerumEmailInput,
	CelerumPasswordInput,
} from "../../../../../../common/ui/src/lib/components/celerum-input/celerum-input.component";
import { loginAction } from "../../../../../data-access/src/lib/authentication.slice";
import styles from "./login-form.module.scss";

type FormProps = ComponentProps<typeof Form>;
export const LoginForm = () => {
	const dispatch = useAppDispatch();
	const { loading, message: errorMessage } = useAppSelector(
		(state) => state.authentication,
	);
	const { isMobile } = useAppSelector((state) => state.userInterface);

	const handleLogin: FormProps["onSubmit"] = (loginData) => {
		dispatch(
			loginAction({
				username: loginData.username,
				password: loginData.password,
			}),
		);
	};

	return (
		<Form
			onSubmit={handleLogin}
			render={(formRenderProps: FormRenderProps) => {
				return (
					<FormElement className={cls(isMobile ? styles.element : "")}>
						<div className={styles.inputs}>
							<Field
								name="username"
								component={CelerumEmailInput}
								type="email"
							/>
							<Field
								name="password"
								component={CelerumPasswordInput}
								label="Password"
								errorMessage={errorMessage}
							/>
							<Link to="/forgotten-password" className={styles.inputs__link}>
								Forgotten password?
							</Link>
						</div>
						<CelerumButton
							type="submit"
							style={{
								width: "100%",
							}}
							disabled={!formRenderProps.allowSubmit}
							title={loading ? "Loading..." : "Log in"}
						/>
					</FormElement>
				);
			}}
		/>
	);
};
