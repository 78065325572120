import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	ISubcontractorRequestDto,
	ISubcontractorResponseDto,
} from "../../../../common/models/src/lib/interfaces/subcontractor.interface";
import { jobApi } from "../../../../common/utils/src/lib/axios.utils";
import { buildQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";

export const fetchSubcontractorsAll = async () => {
	const response = await jobApi.get<CelerumResponse>("/Subcontractor");
	return response.data;
};

export const fetchSubcontractors = async (params: CelerumQueryParams) => {
	const { page, pageSize } = params;
	const query = buildQueryString(params);

	const response = await jobApi.get<CelerumResponse>(`/Subcontractor${query}`);
	return {
		...response.data,
		page,
		pageSize,
	};
};

export const createSubcontractor = async (
	subcontractor: ISubcontractorRequestDto,
) => {
	const response = await jobApi.post<ISubcontractorResponseDto>(
		"/Subcontractor",
		subcontractor,
	);
	return response.data;
};

export const updateSubcontractor = async (
	subcontractor: ISubcontractorRequestDto,
) => {
	const response = await jobApi.put<ISubcontractorResponseDto>(
		"/Subcontractor",
		subcontractor,
	);
	return response.data;
};

export const deleteSubcontractor = async (id: number) => {
	await jobApi.delete(`/Subcontractor/${id}`);
	return id;
};
