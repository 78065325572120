import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	ITruckRequestDto,
	ITruckResponseDto,
} from "../../../../common/models/src/lib/interfaces/truck.interface";
import { jobApi } from "../../../../common/utils/src/lib/axios.utils";
import { buildQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";

export const fetchTrucks = async (params: CelerumQueryParams) => {
	const { page, pageSize } = params;
	const query = buildQueryString(params);

	const response = await jobApi.get<CelerumResponse>(`/Truck${query}`);

	return {
		...response.data,
		page,
		pageSize,
	};
};

export const createTruck = async (truck: ITruckRequestDto) => {
	const response = await jobApi.post<ITruckResponseDto>("/Truck", truck);
	return response.data;
};

export const updateTruck = async (truck: ITruckRequestDto) => {
	const response = await jobApi.put<ITruckResponseDto>("/Truck", truck);
	return response.data;
};

export const deleteTruck = async (id: number) => {
	await jobApi.delete<number>(`/Truck/${id}`);
	return id;
};
