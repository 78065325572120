export enum CelerumActions {
	Pause = 1,
	Delete = 2,
	Cancel = 3,
	Duplicate = 4,
	Resume = 5,
	"Generate Manifest" = 6,
	"Send to invoice" = 7,
	"Clear Customs Documents" = 8,
	"Send for Review" = 9,
	"Preview Invoice" = 10,
	"Mark as Checked" = 11,
	"Re-invoice" = 12,
	"Mark as Sent" = 13,
	"Send to Operations" = 14,
	"Send to Invoicing Check" = 15,
	Edit = 16,
	"Print All Documents" = 17,
	"Force Complete" = 18,
	"Generate File Front" = 19,
}
