import { CelerumActions } from "../../../../../common/models/src/lib/enums/actions.enum";
import { useAppDispatchWithNotifications } from "../../../../../common/stores/src/lib/utils";
import { deleteDriverAction } from "../../../../data-access/src/lib/drivers.slice";

export const useDriverActionSelected = () => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	return (action: CelerumActions | null, id: number | null, name?: string) => {
		if (!id || !action) {
			return;
		}

		switch (action) {
			case +CelerumActions.Delete: {
				dispatchWithNotifications({
					action: deleteDriverAction,
					payload: id,
					successMessage: `Driver ${name} deleted successfully.`,
					errorMessage: `Could not delete driver ${name}.`,
				});
				break;
			}
		}
	};
};
