export enum CustomerStatus {
	INVALID = 0,
	ACTIVE = 1,
	DELETED = 2,
	INACTIVE = 3,
}

export enum CustomerInvoiceType {
	INVALID = 0,
	SINGLE_INVOICE = 1,
	MULTIPLE_INVOICE = 2,
}
