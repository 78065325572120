import {
	Field,
	Form,
	FormElement,
	type FormProps,
} from "@progress/kendo-react-form";
import type { IBase } from "../../../../../../common/models/src/lib/interfaces/base.interface";
import {
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumSubmitButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumFormInput } from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { requiredValidator } from "../../../../../../common/utils/src/lib/validators/validators";
import {
	createConstraintAction,
	updateConstraintAction,
} from "../../../../../data-access/src/lib/constraints.slice";

interface ConstraintsFormProps {
	formState: IBase | undefined;
	onClose: () => void;
}

export const ConstraintsForm = ({
	formState,
	onClose,
}: ConstraintsFormProps) => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const { loading } = useAppSelector((state) => state.constraints);

	const handleSubmit: FormProps["onSubmit"] = async (dataItem) => {
		if (formState) {
			const actionResult = await dispatchWithNotifications({
				action: updateConstraintAction,
				payload: dataItem as IBase,
				successMessage: `Constraint named ${
					(dataItem as IBase).name
				} updated successfully.`,
				errorMessage: `Could not update constraint named ${
					(dataItem as IBase).name
				}.`,
			});
			updateConstraintAction.fulfilled.match(actionResult) && onClose();
		} else {
			const actionResult = await dispatchWithNotifications({
				action: createConstraintAction,
				payload: dataItem as IBase,
				successMessage: `Constraint named ${
					(dataItem as IBase).name
				} created successfully.`,
				errorMessage: `Could not create constraint named ${
					(dataItem as IBase).name
				}.`,
			});
			createConstraintAction.fulfilled.match(actionResult) && onClose();
		}
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formState}
			render={(formRenderProps) => (
				<FormElement>
					<fieldset className="k-form-fieldset">
						<Field
							id="name"
							name="name"
							label="Name"
							component={CelerumFormInput}
							maxLength={250}
							type="text"
							validator={requiredValidator}
							required
							focused="true"
						/>
					</fieldset>
					<CelerumSubmitButton
						type="submit"
						disabled={!formRenderProps.allowSubmit || loading}
						title="Submit"
					/>
				</FormElement>
			)}
		/>
	);
};
