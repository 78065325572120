import type { JobStatus } from "../../../../common/models/src/lib/enums/job.enum";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	IJob,
	IJobRequestDto,
	IJobResponseDto,
} from "../../../../common/models/src/lib/interfaces/job.interface";
import { jobApi } from "../../../../common/utils/src/lib/axios.utils";
import { buildQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";

export const fetchJobs = async (params: CelerumQueryParams) => {
	const { page, pageSize } = params;
	const query = buildQueryString(params);

	const response = await jobApi.get<CelerumResponse>(`/Job${query}`);

	return {
		...response.data,
		page,
		pageSize,
	};
};

export const fetchJobById = async (id: number) => {
	const response = await jobApi.get(`Job/${id}`);
	return {
		...response.data,
		assignedUser: response.data.assignedUser,
		createdByUser: response.data.createdByUser,
	};
};

export const createJob = async (job: IJobRequestDto) => {
	const response = await jobApi.post<IJobResponseDto>("/Job", job);
	return response.data;
};

export const updateJob = async (job: IJob) => {
	const response = await jobApi.put("/Job", job);
	return response.data;
};

export const partialUpdateJob = async (job: IJob) => {
	const response = await jobApi.put("/Job/partial", job);
	return response.data;
};

export const duplicateJob = async (job: IJob, id: number) => {
	const response = await jobApi.post(`/Job/duplicate/${id}`, job);
	return response.data;
};

export const changeJobStatus = async (id: number, status: JobStatus) => {
	await jobApi.post(`/Job/${id}/change-status/${status}`);
	return { id, status };
};

export const pauseJob = async (id: number) => {
	await jobApi.post(`/Job/${id}/change-status/pause`);
	return id;
};

export const resumeJob = async (id: number) => {
	await jobApi.post(`/Job/${id}/change-status/resume`);
	return id;
};

export const cancelJob = async (id: number) => {
	await jobApi.post(`/Job/${id}/change-status/cancel`);
	return id;
};

export const deleteJob = async (id: number) => {
	await jobApi.delete(`/Job/${id}`);
	return id;
};

export const forceComplete = async (id: number) => {
	await jobApi.post(`/Job/${id}/force-complete`);
	return id;
};

export const generateFileFront = async (id: number) => {
	const response = await jobApi.get<Blob>(`Job/report/${id}/fileFront`, {
		responseType: "blob",
	});
	return response.data;
};
