import type { ComponentProps } from "react";
import { jobApi, toasted, useLookupCustomers } from "../helpers";
import { LocationForm } from "./LocationForm";

type LocationFormProps = ComponentProps<typeof LocationForm>;

export const LocationFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<LocationFormProps, "onSubmit" | "defaultValues">) => (
	<LocationForm
		defaultValues={defaultValues}
		lCustomers={useLookupCustomers()}
		onSubmit={async (data) => {
			const { id, ...rest } = data;
			const processData = async () => {
				if (id) {
					await jobApi.location.locationUpdate({ id, ...rest });
					onSubmit(data);
				} else {
					const result = await jobApi.location.locationCreate({ ...rest });
					type TheRightType = Parameters<typeof onSubmit>[0];
					onSubmit({ ...result.data, ...data } as TheRightType);
				}
			};
			toasted(processData(), id ? "Updating Location" : "Creating Location");
		}}
	/>
);
