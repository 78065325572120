import { dollarIcon } from "@progress/kendo-svg-icons";
import {
	operatorIcon,
	percentageIcon,
	truckIcon,
} from "../../../../../../common/models/src/lib/constants/icon.constants";
import { ABSENT } from "../../../../../../common/models/src/lib/constants/messages.constants";
import type { ILoadDetails } from "../../../../../../common/models/src/lib/interfaces/load.interface";
import { useAppSelector } from "../../../../../../common/stores/src/lib/utils";
import { CelerumDetailsCard } from "../../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import {
	getFormattedCurrency,
	getFormattedValue,
} from "../../../../../../common/utils/src/lib/helpers/currency.helpers";
import styles from "./load-information-grid.module.scss";

interface LoadInformationGridProps {
	loadDetails: ILoadDetails;
}

export const LoadInformationGrid = ({
	loadDetails,
}: LoadInformationGridProps) => {
	const { assignedUser, weight, profit, cost, revenue, sharedCost, loadType } =
		loadDetails;
	const { currentCurrency: currency } = useAppSelector(
		(state) => state.currencies,
	);

	return (
		<>
			<div className={styles.container}>
				<CelerumDetailsCard
					svgIconStyle={styles.svgIcon}
					svgIcon={operatorIcon}
					label="Operator"
					value={
						assignedUser
							? `${assignedUser.firstName} ${assignedUser.lastName}`
							: ABSENT
					}
				/>
				<CelerumDetailsCard
					svgIcon={truckIcon}
					label="Truck Weight"
					value={weight ? `${weight} kg` : ABSENT}
				/>
				<CelerumDetailsCard
					svgIcon={dollarIcon}
					label="Shared Cost"
					value={
						sharedCost
							? getFormattedCurrency(sharedCost, currency?.code)
							: ABSENT
					}
				/>
				<CelerumDetailsCard
					svgIcon={percentageIcon}
					label="Profit"
					value={
						profit !== undefined
							? `${getFormattedValue(profit, currency?.code)}%`
							: ABSENT
					}
				/>
				<CelerumDetailsCard
					svgIcon={dollarIcon}
					label="Total Cost"
					value={cost ? getFormattedCurrency(cost, currency?.code) : ABSENT}
				/>
				<CelerumDetailsCard
					svgIcon={dollarIcon}
					label="Revenue"
					value={
						revenue ? getFormattedCurrency(revenue, currency?.code) : ABSENT
					}
				/>
			</div>
			<div className={styles.wrapper}>
				<CelerumDetailsCard
					svgIcon={truckIcon}
					label="Load Type"
					value={loadType?.name ?? ABSENT}
				/>
			</div>
		</>
	);
};
