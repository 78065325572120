import { SvgIcon } from "@progress/kendo-react-common";
import {
	MultiViewCalendar,
	type SelectionRange,
} from "@progress/kendo-react-dateinputs";
import { filterIcon } from "@progress/kendo-svg-icons";
import { useEffect, useRef, useState } from "react";
import type { IDateFilter } from "../../../../../../../models/src/lib/interfaces/filter.interface";
import { constructDerivedRange } from "../../../../../../../utils/src/lib/helpers/date.helpers";
import {
	CelerumButton,
	CelerumWhiteButton,
} from "../../../celerum-buttons/celerum-buttons.component";
import { CelerumPopup } from "../../celerum-popup.component";
import styles from "./celerum-popup-date-range-selection.module.scss";

interface CelerumPopupDateRangeSelectionProps {
	title: string;
	show: boolean | undefined;
	anchor: React.RefObject<HTMLElement>;
	initialRange: IDateFilter[];
	handleSubmit: (value: SelectionRange | null) => void;
	onClose?: () => void;
}

export const CelerumPopupDateRangeSelection = ({
	title,
	show,
	anchor,
	initialRange,
	handleSubmit,
	onClose,
}: CelerumPopupDateRangeSelectionProps) => {
	const containerRef = useRef<HTMLDivElement>(null);

	const [selectedRange, setSelectedRange] = useState<SelectionRange | null>(
		initialRange?.length ? constructDerivedRange(initialRange[0]) : null,
	);

	const handleRangeChange = (value: SelectionRange) => {
		setSelectedRange(value);
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				onClose &&
				containerRef.current &&
				!containerRef.current.contains(event.target as Node) &&
				anchor.current &&
				!anchor.current.contains(event.target as Node)
			) {
				onClose();
				setSelectedRange(
					initialRange?.length ? constructDerivedRange(initialRange[0]) : null,
				);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [anchor, initialRange, onClose]);

	return (
		<CelerumPopup anchor={anchor} show={show} className={styles.container}>
			<div ref={containerRef}>
				<div className={styles.header}>
					<SvgIcon icon={filterIcon} />
					<p className={styles.title}>{title}</p>
				</div>
				<MultiViewCalendar
					onChange={(event) => handleRangeChange(event.target.value)}
					mode={"range"}
					value={selectedRange}
				/>
				<div className={styles.buttonsContainer}>
					<CelerumWhiteButton
						title="Clear"
						disabled={!selectedRange}
						onClick={() => {
							setSelectedRange(null);
							handleSubmit(null);
						}}
					/>
					<CelerumButton
						title="Filter"
						disabled={!selectedRange}
						onClick={() => handleSubmit(selectedRange)}
					/>
				</div>
			</div>
		</CelerumPopup>
	);
};
