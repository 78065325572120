import { CustomerStatus } from "../enums/customer.enum";

export const CUSTOMERS_FEATURE_KEY = "customers";
export const CUSTOMERS_PAGE_SIZE = 50;

export const CUSTOMER_STATUSES = [
	{
		id: CustomerStatus.ACTIVE,
		name: "Active",
	},
	{
		id: CustomerStatus.INACTIVE,
		name: "Inactive",
	},
];

export const NEW_CUSTOMER_STATE = {
	id: -1,
	status: 0,
	name: "",
	accountCode: "",
	invoiceFrequency: "",
	currency: {
		id: -1,
		name: "",
	},
	currencyId: -1,
};

export const CUSTOMER_STATUS_MAPPINGS: {
	[key: number]: { title: string; className: string };
} = {
	0: {
		title: "Invalid",
		className: "gray",
	},
	1: {
		title: "Active",
		className: "green",
	},
	2: {
		title: "Deleted",
		className: "red",
	},
	3: {
		title: "Inactive",
		className: "gray",
	},
};
