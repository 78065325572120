import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type { ITruckType } from "../../../../common/models/src/lib/interfaces/truck-type.interface";
import { jobApi } from "../../../../common/utils/src/lib/axios.utils";
import { buildQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";

export const fetchTruckTypes = async (params: CelerumQueryParams) => {
	const query = buildQueryString(params);

	const response = await jobApi.get<CelerumResponse>(`/TruckType${query}`);
	return response.data;
};

export const createTruckType = async (truckType: ITruckType) => {
	const response = await jobApi.post<ITruckType>("/TruckType", truckType);
	return response.data;
};

export const updateTruckType = async (truckType: ITruckType) => {
	const response = await jobApi.put<ITruckType>("/TruckType", truckType);
	return response.data;
};

export const deleteTruckType = async (id: number) => {
	await jobApi.delete<number>(`/TruckType/${id}`);
	return id;
};
