import { SvgIcon } from "@progress/kendo-react-common";
import {
	checkMarkIcon,
	crossMarkIcon,
} from "../../../../../../common/models/src/lib/constants/icon.constants";
import { PasswordFormState } from "../../../../../../common/models/src/lib/enums/password-form-state.enum";
import type { PasswordErrorInterface } from "../../../../../../common/models/src/lib/interfaces/password-error.interface";

interface PasswordMessageContainerProps {
	styles: Record<string, string>;
	errorMessages: PasswordErrorInterface[];
	isFormValid: PasswordFormState;
}

export const PasswordMessageContainer = ({
	styles,
	errorMessages,
	isFormValid,
}: PasswordMessageContainerProps) => (
	<div className={styles.messageContainer}>
		<h3 className={styles.description}>Your password needs to:</h3>
		<br />
		{isFormValid === PasswordFormState.Inactive
			? errorMessages.map((e) => (
					<div key={e.message} className={styles.inactiveMessage}>
						{e.message}
					</div>
				))
			: errorMessages.map((e) =>
					e.valid ? (
						<div key={e.message} className={styles.validMessage}>
							<SvgIcon style={{ color: "white" }} icon={checkMarkIcon} />{" "}
							{e.message}
						</div>
					) : (
						<div key={e.message} className={styles.invalidMessage}>
							<SvgIcon icon={crossMarkIcon} /> {e.message}
						</div>
					),
				)}
	</div>
);
