import { Icon } from "@progress/kendo-react-common";
import { useCallback } from "react";
import { TypedDropDownButton } from "./TypedDropDownButton";
import {
	type LegStatusType,
	type NewLegType,
	legStatusTypeNames,
	useLegStatus,
} from "./helpers";

type StatusProps = { text: string; color?: string };
const Status = ({ text, color }: StatusProps) => (
	<span>
		<Icon name="circle" style={{ color }} />
		{text}
	</span>
);
type StatusItem = {
	text: string;
	status: LegStatusType;
	color: string;
};
type StatusItemRenderProps = { item: StatusItem };
const StatusItemRender = ({ item }: StatusItemRenderProps) => (
	<Status text={item.text} color={item.color} />
);

type DropDownLegStatusProps = {
	status: LegStatusType;
	type: NewLegType;
	onStatusClicked: (newStatus: LegStatusType) => void;
	disabled?: boolean;
};
export const DropDownLegStatus = ({
	status,
	type,
	onStatusClicked,
	disabled,
}: DropDownLegStatusProps) => {
	const { availableStatuses, getLegColor } = useLegStatus();
	const color = getLegColor(status) ?? "black";
	const statusString = legStatusTypeNames[status] ?? "Unknown";
	const getPossibleStatuses = useCallback(
		() =>
			availableStatuses(type).map(
				(x): StatusItem => ({
					text: legStatusTypeNames[x.id],
					status: x.id,
					color: x.color,
				}),
			),
		[availableStatuses, type],
	);
	return (
		<TypedDropDownButton
			size="small"
			items={[...getPossibleStatuses()]}
			itemRender={StatusItemRender}
			onItemClick={(x) => onStatusClicked(x.item.status)}
			popupSettings={{ animate: false }}
			text={<Status text={statusString} color={color} />}
			style={{ borderColor: color }}
			disabled={disabled}
		/>
	);
};
