import type { LegActions } from "../api/JobApi";
import {
	type GenericGridProps,
	type NewLegType,
	type TypedGridColumnProps,
	toCurrency,
} from "../helpers";
import { toCell } from "../helpersReact";
import { GenericDataGrid } from "./GenericDataGrid";

type Leg = {
	id: number;
	actions: LegActions;
	jobId: number | undefined;
	loadId: number | undefined;
	number: string;
	jobType: string;
	type: NewLegType;
	typeName: string;
	customerName: string;
	assignedToName: string;
	collectionLocationName: string;
	collectionDate: Date;
	deliveryLocationName: string;
	deliveryDate: Date;
	driverName: string;
	supplierInvoiceType: string;
	status: number;
	collectionDateString: string;
	deliveryDateString: string;
	statusString: string;
	currencyCode: string | undefined;
	cost: number | undefined;
	subcontractorName: string;
	isNew: boolean;
};

const defaultColumns: TypedGridColumnProps<Leg>[] = [
	{ field: "number", title: "Number" },
	{ field: "jobType", title: "Job Type" },
	{ field: "typeName", title: "Type" },
	{ field: "customerName", title: "Customer" },
	{ field: "assignedToName", title: "Assigned To" },
	{ field: "collectionLocationName", title: "Collection Location" },
	{
		field: "collectionDate",
		title: "Collection Date",
		cell: ({ dataItem }) => toCell(dataItem.collectionDateString),
	},
	{ field: "deliveryLocationName", title: "Delivery Location" },
	{
		field: "deliveryDate",
		title: "Delivery Date",
		cell: ({ dataItem }) => toCell(dataItem.deliveryDateString),
	},
	{
		field: "cost",
		title: "Cost",
		filter: "numeric",
		cell: ({ dataItem }) =>
			toCell(
				dataItem.cost ? toCurrency(dataItem.cost, dataItem.currencyCode) : "",
			),
	},
	{ field: "currencyCode", title: "Currency", hidden: true },
	{ field: "driverName", title: "Driver" },
	{ field: "subcontractorName", title: "Subcontractor" },
	{ field: "supplierInvoiceType", title: "Supplier Invoice Type" },
];

export const LegsGrid = (props: GenericGridProps<Leg>) => (
	<GenericDataGrid {...props} defaultColumns={defaultColumns} name="Legs" />
);
