import { useState } from "react";
import { ModalType } from "../../../../../../common/models/src/lib/enums/modal.enums";
import type { IQualification } from "../../../../../../common/models/src/lib/interfaces/qualification.interface";
import {
	CelerumButtonDeleteGrid,
	CelerumButtonEditGrid,
} from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumConfirmModal } from "../../../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import styles from "./qualification-accordion-header.module.scss";

interface QualificationAccordionHeaderProps {
	qualification: IQualification;
	setSelectedQualification: (qualification: IQualification) => void;
	handleDeleteQualification: (qualificationId: number) => void;
}

export const QualificationAccordionHeader = ({
	qualification,
	setSelectedQualification,
	handleDeleteQualification,
}: QualificationAccordionHeaderProps) => {
	const { id, name } = qualification;

	const [showModal, setShowModal] = useState<boolean>(false);

	return (
		<>
			<div className={styles.container}>
				<h1 className={styles.title}>{name}</h1>
				<div className={styles.actions}>
					<CelerumButtonEditGrid
						title="Edit"
						onClick={(event) => {
							event.stopPropagation();
							setSelectedQualification({
								...qualification,
								dateTaken: new Date(qualification.dateTaken),
								expirationDate: new Date(qualification.expirationDate),
							});
						}}
					/>
					<CelerumButtonDeleteGrid
						title="Delete"
						onClick={(event) => {
							event.stopPropagation();
							setShowModal(true);
						}}
					/>
				</div>
			</div>
			<CelerumConfirmModal
				type={ModalType.Delete}
				entityName="qualification"
				entityProperty="named"
				entityValue={name}
				isOpen={showModal}
				title={`Are you sure you want to remove ${name} from this load?`}
				subtitle={"The qualification cannot be recovered."}
				handleSubmit={() => {
					if (id) {
						handleDeleteQualification(id);
					}
					setShowModal(false);
				}}
				handleClose={() => setShowModal(false)}
			/>
		</>
	);
};
