import { JobStatus } from "../../../../../common/models/src/lib/enums/job.enum";
import { LegStatus } from "../../../../../common/models/src/lib/enums/leg.enum";
import type { IJob } from "../../../../../common/models/src/lib/interfaces/job.interface";

/** Function to check if certain leg statuses are present. */
const hasStatuses = (legStatuses: number[]) => {
	const statusesToMiss = [
		LegStatus.InStartPosition,
		LegStatus.InEndPosition,
		LegStatus.Underway,
	];
	return (
		!legStatuses ||
		legStatuses.every((status) => statusesToMiss.indexOf(status) === -1)
	);
};

/** Function to check if a job can be canceled, paused, or resumed. */
const canCancelPauseResume = (job: IJob, targetStatus: JobStatus) => {
	return (
		job.status !== targetStatus &&
		hasStatuses(job.legStatuses) &&
		![JobStatus.NEW, JobStatus.CANCELLED].includes(job.status)
	);
};

export const canDelete = (item: unknown) => {
	const job = item as IJob;
	return job.status === JobStatus.NEW;
};

export const canCancel = (item: unknown) => {
	const job = item as IJob;
	return canCancelPauseResume(job, JobStatus.CANCELLED);
};

export const canPause = (item: unknown) => {
	const job = item as IJob;
	return canCancelPauseResume(job, JobStatus.PAUSED);
};

export const canResume = (item: unknown) => {
	const job = item as IJob;
	return job.status === JobStatus.PAUSED || job.status === JobStatus.CANCELLED;
};

export const canSendToInvoice = (item: unknown) => {
	const job = item as IJob;
	return job.status === JobStatus.COMPLETED;
};

export const canDuplicate = () => true;

export const canForceComplete = (item: unknown) => {
	const job = item as IJob;
	return job.status !== JobStatus.COMPLETED;
};

export const canGenerateFileFront = () => true;
