import { Reveal } from "@progress/kendo-react-animation";
import {
	ExpansionPanel,
	type ExpansionPanelActionEvent,
	ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import cls from "classnames";
import { useMemo, useState } from "react";
import type { ParentType } from "../../../../../../common/models/src/lib/enums/parent-type.enum";
import type { ILegData } from "../../../../../../common/models/src/lib/interfaces/leg.interface";
import type {
	AllLegs,
	AnyLeg,
} from "../../../../../../common/models/src/lib/types/leg.type";
import { useScrollbarDetection } from "../../../../../../common/utils/src/lib/hooks/use-scrollbar-detection.hook";
import { LegAccordionContent } from "../leg-accordion-content/leg-accordion-content.component";
import { LegAccordionHeader } from "../leg-accordion-header/leg-accordion-header.component";
import styles from "./legs-card-container.module.scss";

interface LegsCardContainerProps {
	parentId: string | undefined;
	parentType: ParentType;
	legs: ILegData;
	isJobInvoiced?: boolean;
	setSelectedLeg: (leg: AnyLeg) => void;
}

export const LegsCardContainer = ({
	parentId,
	legs,
	parentType,
	isJobInvoiced = false,
	setSelectedLeg,
}: LegsCardContainerProps) => {
	const { containerRef, hasScrollBar } = useScrollbarDetection();

	const [expanded, setExpanded] = useState<number | undefined>();

	const renderedLegs = useMemo(() => {
		return Object.keys(legs).reduce((accumulator, key) => {
			const array = legs[key as keyof ILegData];
			return array ? accumulator.concat(array) : accumulator;
		}, [] as AllLegs);
	}, [legs]);

	return (
		<div
			className={cls(
				styles.container,
				hasScrollBar ? styles.scrollPadding : "",
			)}
			ref={containerRef}
		>
			{renderedLegs.map((leg: AnyLeg, index: number) => (
				<div key={leg.id} className={styles.accordion}>
					<ExpansionPanel
						title={
							<LegAccordionHeader
								parentId={parentId}
								parentType={parentType}
								leg={leg}
								expanded={expanded === leg.id}
								isJobInvoiced={isJobInvoiced}
								setSelectedLeg={setSelectedLeg}
							/>
						}
						expanded={expanded === leg.id}
						onAction={(event: ExpansionPanelActionEvent) =>
							setExpanded(event.expanded ? undefined : leg.id)
						}
						tabIndex={index}
					>
						<Reveal>
							{expanded === leg.id && (
								<ExpansionPanelContent>
									<LegAccordionContent
										parentType={parentType}
										leg={leg}
										isJobInvoiced={isJobInvoiced}
									/>
								</ExpansionPanelContent>
							)}
						</Reveal>
					</ExpansionPanel>
				</div>
			))}
		</div>
	);
};
