import type { JSXElementConstructor, ReactElement } from "react";
import { CelerumSkeleton } from "../../../celerum-skeleton/celerum-skeleton.component";

interface LoadingCellProps {
	loading: boolean;
	element: ReactElement<
		HTMLTableCellElement,
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		string | JSXElementConstructor<any>
	> | null;
}

export const LoadingCell = ({ loading, element }: LoadingCellProps) => {
	if (loading) {
		return (
			<td>
				<CelerumSkeleton />
			</td>
		);
	}

	return element;
};
