import styles from "./celerum-user-profile.module.scss";

interface CelerumUserProfileProps {
	firstName: string;
	lastName: string;
}

export const CelerumUserProfile = ({
	firstName,
	lastName,
}: CelerumUserProfileProps) => (
	<div className={styles.profileContainer}>
		{firstName[0]} {lastName[0]}
	</div>
);
