import type { IActivateAccount } from "../../../../common/models/src/lib/interfaces/authentication.interface";
import type { IBusinessUnit } from "../../../../common/models/src/lib/interfaces/business-unit.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import { authenticationApi } from "../../../../common/utils/src/lib/axios.utils";

export const login = async (username: string, password: string) => {
	const response = await authenticationApi.post("/Authentication", {
		username,
		password,
	});
	return response.data;
};

export const fetchUsersByRoleName = async (types: string[]) => {
	const queryString = types.map((type) => `roleNames=${type}`).join("&");
	const response = await authenticationApi.get<CelerumResponse>(
		`/User/ByRoleNames?${queryString}`,
	);
	return response.data;
};

export const logout = async () => {
	const response = await authenticationApi.post("/Authentication/Logout");
	return response.data;
};

export const forgotPassword = async (email: string) => {
	const response = await authenticationApi.post(
		"/Authentication/ForgotPassword",
		{
			email,
		},
	);
	return response.data;
};

export const resetPassword = async (
	email: string | null,
	password: string,
	token: string | null | undefined,
) => {
	const response = await authenticationApi.post(
		"/Authentication/ResetPassword",
		{
			email,
			password,
			token,
		},
	);
	return response.data;
};

export const activateAccount = async (authenticationItem: IActivateAccount) => {
	const response = await authenticationApi.post(
		"/Authentication/ActivateAccount",
		authenticationItem,
	);
	return response.data;
};

export const resendActivationEmail = async (email: string) => {
	const response = await authenticationApi.post(
		"/Authentication/ResendActivationEmail",
		{ email },
	);
	return response.data;
};

export const fetchBusinessUnit = async (
	id: string | number,
): Promise<IBusinessUnit> => {
	const response = await authenticationApi.get(`/BusinessUnit/${id}`);
	return response.data;
};
