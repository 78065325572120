import { useTitle } from "react-use";
import { ForgotPassword } from "../../../libs/authentication/feature/src/lib/forgot-password/forgot-password.component";
import styles from "./forgot-password.module.scss";

export const ForgotPasswordPage = () => {
	useTitle("Forgotten Password");

	return (
		<div className={styles.root}>
			<div className={styles.leftSide} />
			<div className={styles.rightSide}>
				<ForgotPassword />
			</div>
		</div>
	);
};
