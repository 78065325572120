import type { AttachmentUsage } from "../../../../common/models/src/lib/enums/attachment.enum";
import { jobApi } from "../../../../common/utils/src/lib/axios.utils";

export const getAttachmentUri = async (attachmentId: number | string) => {
	const response = await jobApi.get(`/attachment/${attachmentId}`);
	return response.data;
};

export const deleteAttachment = async (attachmentId: number | string) => {
	await jobApi.delete(`/attachment/${attachmentId}`);
	return attachmentId;
};

export const addAttachments = async (
	jobForm: FormData,
	entityId: number | string,
	entity: AttachmentUsage,
	isPod?: boolean,
) => {
	let requestURL = `/attachment/${entityId}?usage=${entity}`;

	if (isPod) {
		requestURL += "&isPod=true";
	}

	const response = await jobApi.post(requestURL, jobForm, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};
