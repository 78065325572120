import { SvgIcon } from "@progress/kendo-react-common";
import cls from "classnames";
import type { MutableRefObject } from "react";
import { chevronIcon } from "../../../../../models/src/lib/constants/icon.constants";
import styles from "./celerum-dropdown-category.module.scss";

interface CelerumDropdownCategoryProps {
	name: string;
	anchor?: MutableRefObject<HTMLDivElement | null>;
	filtered?: boolean;
	show?: boolean;
	filtersCount?: number;
	renderChevron?: boolean;
	onClick?: () => void;
}

export const CelerumDropdownCategory = ({
	name,
	anchor,
	filtered,
	show,
	filtersCount,
	renderChevron = true,
	onClick,
}: CelerumDropdownCategoryProps) => (
	<div
		className={styles.container}
		onClick={onClick}
		onKeyUp={onClick}
		ref={anchor}
	>
		<p className={filtered ? styles.filtered : styles.normal}>{name}</p>
		{filtersCount !== undefined && (
			<div
				className={cls(
					styles.count,
					filtersCount === 0 ? styles.count__normal : styles.count__filtered,
				)}
			>
				<span className={styles.count__title}>{filtersCount}</span>
			</div>
		)}
		{renderChevron && (
			<SvgIcon
				icon={chevronIcon}
				className={cls(
					filtered ? styles.chevron__filtered : styles.chevron__normal,
					show ? styles.chevron__up : styles.chevron__down,
				)}
			/>
		)}
	</div>
);
