import { useMemo, useState } from "react";
import { editGridIconWhite } from "../../../../../common/models/src/lib/constants/icon.constants";
import { ModalSize } from "../../../../../common/models/src/lib/enums/modal.enums";
import { useAppSelector } from "../../../../../common/stores/src/lib/utils";
import { CelerumCustomCard } from "../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { CelerumLoader } from "../../../../../common/ui/src/lib/components/celerum-loader/celerum-loader.components";
import { CelerumModal } from "../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { DriverForm } from "../components/driver-form/driver-form.component";
import { DriverInformationCardContainer } from "../components/driver-information-card-container/driver-information-card-container.component";
import styles from "./driver-information-feature.module.scss";

interface DriverInformationFeatureProps {
	className: string | undefined;
}

export const DriverInformationFeature = ({
	className,
}: DriverInformationFeatureProps) => {
	const { driver, loading } = useAppSelector((state) => state.drivers);

	const [showModal, setShowModal] = useState<{ update: boolean }>({
		update: false,
	});

	const renderedDriverDetails = useMemo(() => {
		if (driver) {
			return {
				...driver,
				licenceExpirationDate: new Date(driver.licenceExpirationDate),
			};
		}
	}, [driver]);

	const closeModal = () => {
		setShowModal({ update: false });
	};

	return (
		<>
			<CelerumCustomCard
				buttonName="Edit Info"
				buttonSvgIcon={editGridIconWhite}
				onClick={() => setShowModal({ ...showModal, update: true })}
				title="Driver Information"
				className={className}
				disabled={loading.driver || !driver}
			>
				{loading.driver ? (
					<div className={styles.verticalWrapper}>
						<CelerumLoader visible />
					</div>
				) : driver ? (
					<DriverInformationCardContainer driverDetails={driver} />
				) : (
					!loading.driver && (
						<div className={styles.verticalWrapper}>
							<div className={styles.messageWrapper}>
								<span>
									There was a problem while loading driver information.
								</span>
								<span>Please try again later.</span>
							</div>
						</div>
					)
				)}
			</CelerumCustomCard>
			{renderedDriverDetails && (
				<CelerumModal
					title="Edit Driver Information"
					width={ModalSize.Large}
					visible={showModal.update}
					toggleDialog={closeModal}
				>
					<DriverForm formState={renderedDriverDetails} onClose={closeModal} />
				</CelerumModal>
			)}
		</>
	);
};
