import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	ICurrencyExchangeRate,
	ICurrencyExchangeRateRequestDto,
} from "../../../../common/models/src/lib/interfaces/currency-exchange-rate.interface";
import { jobApi } from "../../../../common/utils/src/lib/axios.utils";

export const fetchCurrencyExchangeRates = async () => {
	const response = await jobApi.get<CelerumResponse>("/CurrencyExchangeRate");
	return response.data;
};

export const createCurrencyExchangeRate = async (
	currencyExchangeRate: ICurrencyExchangeRateRequestDto,
) => {
	const response = await jobApi.post<ICurrencyExchangeRate>(
		"/CurrencyExchangeRate",
		currencyExchangeRate,
	);
	return response.data;
};

export const updateCurrencyExchangeRate = async (
	currencyExchangeRate: ICurrencyExchangeRateRequestDto,
) => {
	const response = await jobApi.put<ICurrencyExchangeRate>(
		"/CurrencyExchangeRate",
		currencyExchangeRate,
	);
	return response.data;
};

export const deleteCurrencyExchangeRate = async (id: number) => {
	try {
		await jobApi.delete(`/CurrencyExchangeRate/${id}`);
		return id;
	} catch {
		return null;
	}
};
