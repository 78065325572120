import { type InferType, array, date, number, object, string } from "yup";

export const GoodSchema = object({
	id: number().nullable().label("ID"),
	description: string().nullable().label("Description"),
	revenue: number().required().label("Revenue"),
	quantity: number().nullable().label("Quantity"),
	weight: number().nullable().label("Weight"),
	constraintIds: array(number().required()).label("Constraints"),
	collectionLocationId: number().nullable().label("Collection Location"),
	collectionDate: date().required().label("Collection Date"),
	deliveryLocationId: number().nullable().label("Delivery Location"),
	deliveryDate: date().required().label("Delivery Date"),
	notes: string().nullable().label("Notes"),
	jobId: number().required().label("Job ID"),
});

export type GoodFormObject = InferType<typeof GoodSchema>;
