import type { Libraries } from "@react-google-maps/api";

export const LOCATIONS_FEATURE_KEY = "locations";
export const LOCATIONS_PAGE_SIZE = 50;

export const LONDON_LOCATION = {
	lat: 51.5074,
	lng: 0.1278,
};

export const LIBRARIES: Libraries = ["places"];

export const ICON_URL =
	"https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png";
