export const GOODS_FEATURE_KEY = "goods";

export const GOODS_PAGE_SIZE = 50;

export const NEW_GOODS_STATE = {
	id: -1,
	name: "",
	jobId: 0,
	quantity: 0,
	height: 0,
	length: 0,
	width: 0,
	weight: 0,
	status: 0,
	onTimeCollection: false,
	collectionDate: null,
	onTimeDelivery: false,
	deliveryDate: null,
	notes: "",
	collectionLocation: null,
	deliveryLocation: null,
	jobType: null,
	customer: null,
	jobNumber: "",
	jobStatus: 0,
	constraints: [],
};
