import { Navigate, Outlet } from "react-router-dom";
import { AuthorizationWrapper } from "../authorization-wrapper/authorization-wrapper.component";

interface PrivateRoutesProps {
	token: string | undefined;
}

export const PrivateRoutes = ({ token }: PrivateRoutesProps) => {
	return token ? (
		<AuthorizationWrapper>
			<Outlet />
		</AuthorizationWrapper>
	) : (
		<Navigate to="/login" />
	);
};
