import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../../common/stores/src/lib/utils";
import { Unauthorized } from "../unauthorized/unauthorized.component";

interface AuthorizationWrapperProps {
	children: JSX.Element;
}

export const AuthorizationWrapper = (props: AuthorizationWrapperProps) => {
	const location = useLocation();

	const { authorizedRoutes } = useAppSelector((state) => state.userInterface);

	const isAuthorized = useMemo(() => {
		const route = location.pathname.split("/")[1];
		return (
			authorizedRoutes.find((item: string) => item === route) !== undefined
		);
	}, [location.pathname, authorizedRoutes]);

	return isAuthorized ? props.children : <Unauthorized />;
};
