import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import type { ReactNode } from "react";
import { DFlex } from "../../display/DFlex";

type JobDetailsCardProps = {
	title: ReactNode;
	buttons: ReactNode;
	children: ReactNode;
};
export const JobDetailsCard = ({
	title,
	buttons,
	children,
}: JobDetailsCardProps) => {
	return (
		<Card>
			<CardHeader>
				<DFlex spaceBetween>
					<h3 style={{ alignContent: "center" }}>{title}</h3>
					<DFlex gap="8px" center>
						{buttons}
					</DFlex>
				</DFlex>
			</CardHeader>
			<CardBody style={{ height: "0", overflow: "auto" }}>{children}</CardBody>
		</Card>
	);
};
