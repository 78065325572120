import type { FieldValidatorType } from "@progress/kendo-react-form";
import {
	GREATER_THAN_OR_EQUAL_TO_ZERO_VALIDATION_MESSAGE,
	GREATER_THAN_ZERO_VALIDATION_MESSAGE,
	REQUIRED_VALIDATION_MESSAGE,
} from "../../../../models/src/lib/constants/messages.constants";

export const requiredValidator: FieldValidatorType = (value) =>
	value ? "" : REQUIRED_VALIDATION_MESSAGE;

export const passwordValidators = [
	(password: string) => ({
		message: "Be at least 8 characters in length",
		valid: password.length >= 8,
	}),
	(password: string) => {
		const lowerCase = /[a-z]/;
		return {
			message: "Include lower case letters (a-z)",
			valid: lowerCase.test(password),
		};
	},
	(password: string) => {
		const upperCase = /[A-Z]/;
		return {
			message: "Include upper case letters (A-Z)",
			valid: upperCase.test(password),
		};
	},
	(password: string) => {
		const number = /[0-9]/;
		return {
			message: "Include numbers (0-9)",
			valid: number.test(password),
		};
	},
	(password: string) => {
		const specialChar = /[""!@$%^&*(){}:;<>,.?/+_=|'~\\-]/;
		return {
			message: "Include Special Characters (!, @, etc)",
			valid: specialChar.test(password),
		};
	},
	(password: string) => {
		const cantInclude = /^[^£#"“”\s]+$/;
		return {
			message: "Must not contain the following characters £ # “” or spaces",
			valid: cantInclude.test(password),
		};
	},
];

export const isEqualValidator = (
	password: string,
	confirmPassword: string,
) => ({
	message: "Be equal to confirm password.",
	valid: password === confirmPassword,
});

export const greaterThanZeroValidator = (value: number) =>
	value > 0 ? "" : GREATER_THAN_ZERO_VALIDATION_MESSAGE;
export const noNegativeValidator = (value: number) =>
	value >= 0 ? "" : GREATER_THAN_OR_EQUAL_TO_ZERO_VALIDATION_MESSAGE;
