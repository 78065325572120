import {
	DropDownButton,
	type DropDownButtonItemClickEvent,
	type DropDownButtonProps,
} from "@progress/kendo-react-buttons";
import type { ComponentType } from "react";

type TypedDropDownButtonItemClickEvent<T extends object> = Omit<
	DropDownButtonItemClickEvent,
	"item"
> & {
	item: T;
};

type TypedDropDownButtonProps<T extends object> = Omit<
	DropDownButtonProps,
	"items" | "itemRender" | "onItemClick"
> & {
	items: T[];
	itemRender: ComponentType<{ item: T; itemIndex: number }>;
	onItemClick: (props: TypedDropDownButtonItemClickEvent<T>) => void;
};
export const TypedDropDownButton = <T extends object>({
	...props
}: TypedDropDownButtonProps<T>) => {
	return <DropDownButton {...props} />;
};
