import axios from "axios";
import { environment } from "../../../../environments/src/environment/environment";
import { ErrorCode } from "../../../models/src/lib/enums/error-codes.enum";

const createAxiosInstance = (baseUrl: string) => {
	const axiosInstance = axios.create({
		baseURL: baseUrl,
		headers: {
			"Content-Type": "application/json",
			Application: "client",
		},
	});

	axiosInstance.interceptors.request.use(
		(config) => {
			const persistedAuthentication = localStorage.getItem(
				"persist:authentication",
			);

			if (persistedAuthentication) {
				const authenticationState = JSON.parse(persistedAuthentication);
				const { token, currentBusinessUnit } = authenticationState;

				if (token) {
					config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
				}

				if (currentBusinessUnit) {
					config.headers.BusinessUnit = JSON.parse(currentBusinessUnit).id;
				}
			}

			return config;
		},
		(error) => {
			return Promise.reject(error);
		},
	);

	axiosInstance.interceptors.response.use(
		(response) => {
			if (!response) {
				return Promise.reject("Response canceled!");
			}
			return response;
		},
		(error) => {
			console.log(error);

			const responseData = error.response?.data;
			const statusCode = error.response?.status;

			if (statusCode === 401) {
				localStorage.clear();
				window.location.href = "/login";
			} else if (responseData) {
				switch (responseData.errorCode) {
					case ErrorCode.INVALID_TOKEN:
						localStorage.clear();
						window.location.href = "/login";
						break;
					case ErrorCode.FORBIDDEN:
						window.location.href = "/business-unit-selection";
						break;
				}
			}

			return Promise.reject({
				code: responseData?.errorCode?.toString() || "Unknown",
				message: responseData?.message || "An error occurred",
			});
		},
	);

	return axiosInstance;
};

const authenticationApi = createAxiosInstance(environment.authenticationApiUrl);
const jobApi = createAxiosInstance(environment.jobApiUrl);
const reportApi = createAxiosInstance(environment.reportApiUrl);

export { authenticationApi, jobApi, reportApi };
