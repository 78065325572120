import { LegType } from "../../../../models/src/lib/enums/leg.enum";
import type { ILegSummary } from "../../../../models/src/lib/interfaces/leg.interface";
import type { AnyLeg } from "../../../../models/src/lib/types/leg.type";

export const renderLegTypeTitle = (leg: AnyLeg | ILegSummary) => {
	switch (leg.type) {
		case LegType.Collection:
			return "Collection";
		case LegType.CollectDeliver:
			return "Collect Deliver";
		case LegType.Delivery:
			return "Delivery";
		case LegType.Segment:
			return "Segment";
		case LegType.Storage:
			return "Storage";
		case LegType.ClearCustoms:
			return "Clear Customs";
		case LegType.Ferry:
			return "Ferry";
		case LegType.ContainerDropOff:
			return "Container Drop Off";
		case LegType.ContainerPickUp:
			return "Container Pick Up";
		case LegType.ContainerGoodsCollection:
			return "Container Goods Collection";
		case LegType.ContainerGoodsDelivery:
			return "Container Goods Delivery";
		case LegType.LoadAndLash:
			return "Load And Lash";
		case LegType.Devan:
			return "Devan";
		case LegType.Blank:
			return "Blank";
		default:
			return "Collection";
	}
};
