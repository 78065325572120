import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	IUserRequestDto,
	IUserResponseDto,
} from "../../../../common/models/src/lib/interfaces/user.interface";
import { authenticationApi } from "../../../../common/utils/src/lib/axios.utils";
import { buildQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";

export const fetchUsers = async (params: CelerumQueryParams) => {
	const { page, pageSize } = params;
	const query = buildQueryString(params);

	const response = await authenticationApi.get<CelerumResponse>(
		`/User${query}`,
	);
	return {
		...response.data,
		page,
		pageSize,
	};
};

export const deleteUser = async (id: number | string) => {
	await authenticationApi.delete(`/User/${id}`);
	return id;
};

export const createUser = async (user: IUserRequestDto) => {
	const response = await authenticationApi.post<IUserResponseDto>(
		"/User",
		user,
	);
	return response.data;
};

export const updateUser = async (user: IUserRequestDto) => {
	const response = await authenticationApi.put<IUserResponseDto>("/User", user);
	return response.data;
};

export const fetchRoles = async () => {
	const response = await authenticationApi.get<CelerumResponse>("/Role");
	return response.data;
};
