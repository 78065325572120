import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { USER_INTERFACE_FEATURE_KEY } from "../../../../common/models/src/lib/constants/user-interface.constants";
import { NotificationType } from "../../../../common/models/src/lib/enums/notification-type.enum";
import type { UserInterfaceState } from "../../../../common/models/src/lib/interfaces/user-interface.interface";

const initialState: UserInterfaceState = {
	notificationType: null,
	message: "",
	navbarExpanded: false,
	isMobile: false,
	isMapLoaded: false,
	authorizedRoutes: [],
};

const userInterfaceSlice = createSlice({
	name: USER_INTERFACE_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		resetNotificationAction: (state: UserInterfaceState) => {
			state.notificationType = initialState.notificationType;
			state.message = initialState.message;
		},
		triggerSuccessNotificationAction: (
			state: UserInterfaceState,
			action: PayloadAction<string>,
		) => {
			state.notificationType = NotificationType.Success;
			state.message = action.payload;
		},
		triggerErrorNotificationAction: (
			state: UserInterfaceState,
			action: PayloadAction<string>,
		) => {
			state.notificationType = NotificationType.Error;
			state.message = action.payload;
		},
		setNavbarExpandedAction: (
			state: UserInterfaceState,
			action: PayloadAction<boolean>,
		) => {
			state.navbarExpanded = action.payload;
		},
		setIsMobileAction: (
			state: UserInterfaceState,
			action: PayloadAction<boolean>,
		) => {
			state.isMobile = action.payload;
		},
		setIsMapLoaded: (
			state: UserInterfaceState,
			action: PayloadAction<boolean>,
		) => {
			state.isMapLoaded = action.payload;
		},
		setAuthorizedRoutesAction: (
			state: UserInterfaceState,
			action: PayloadAction<string[]>,
		) => {
			state.authorizedRoutes = action.payload;
		},
	},
});

/** Reducer */
export const userInterfaceReducer = userInterfaceSlice.reducer;

/** Actions */
export const {
	resetNotificationAction,
	triggerSuccessNotificationAction,
	triggerErrorNotificationAction,
	setNavbarExpandedAction,
	setIsMapLoaded,
	setAuthorizedRoutesAction,
} = userInterfaceSlice.actions;
