import {
	Field,
	Form,
	FormElement,
	type FormProps,
} from "@progress/kendo-react-form";
import type { ITruckType } from "../../../../../../common/models/src/lib/interfaces/truck-type.interface";
import {
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumSubmitButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumFormInput } from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { requiredValidator } from "../../../../../../common/utils/src/lib/validators/validators";
import {
	createTruckTypeAction,
	updateTruckTypeAction,
} from "../../../../../data-access/src/lib/truck-types.slice";

interface TruckTypeFormProps {
	formState: ITruckType | undefined;
	onClose: () => void;
}

export const TruckTypeForm = ({ formState, onClose }: TruckTypeFormProps) => {
	const dispatchWithNotification = useAppDispatchWithNotifications();
	const { loading } = useAppSelector((state) => state.truckTypes);

	const handleSubmit: FormProps["onSubmit"] = async (dataItem) => {
		if (formState) {
			const actionResult = await dispatchWithNotification({
				action: updateTruckTypeAction,
				payload: dataItem as ITruckType,
				successMessage: `Truck type named ${formState.name} updated successfully.`,
				errorMessage: `Could not update truck type named ${formState.name}.`,
			});
			updateTruckTypeAction.fulfilled.match(actionResult) && onClose();
		} else {
			const actionResult = await dispatchWithNotification({
				action: createTruckTypeAction,
				payload: dataItem as ITruckType,
				successMessage: `Truck type named ${dataItem.name} created successfully.`,
				errorMessage: `Could not create truck type named ${dataItem.name}.`,
			});
			createTruckTypeAction.fulfilled.match(actionResult) && onClose();
		}
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formState}
			render={(formRenderProps) => (
				<FormElement>
					<fieldset className="k-form-fieldset">
						<Field
							id="name"
							name="name"
							label="Name"
							component={CelerumFormInput}
							maxLength={100}
							type="text"
							validator={requiredValidator}
							required
							focused="true"
						/>
					</fieldset>
					<CelerumSubmitButton
						type="submit"
						disabled={!formRenderProps.allowSubmit || loading}
						title="Submit"
					/>
				</FormElement>
			)}
		/>
	);
};
