import { LegType } from "../../../../models/src/lib/enums/leg.enum";
import type {
	IBlankLeg,
	IClearCustomsLeg,
	IFerryLeg,
	ILegData,
	IOperationLeg,
	IStorageLeg,
	LegsState,
} from "../../../../models/src/lib/interfaces/leg.interface";
import type { AnyLeg } from "../../../../models/src/lib/types/leg.type";

const updateArray = <
	T extends
		| IOperationLeg
		| IClearCustomsLeg
		| IStorageLeg
		| IFerryLeg
		| IBlankLeg,
>(
	array: T[],
	leg: T,
	insertType: "create" | "update",
): void => {
	if (insertType === "create") {
		array.push(leg);
	} else {
		const index = array.findIndex((l) => l.id === leg.id);
		array[index] = leg;
	}
};

export const updateLegStateData = (
	state: LegsState,
	legTypeName: string,
	leg: AnyLeg,
	insertType: "create" | "update",
): void => {
	if (!state.data[legTypeName as keyof ILegData]) {
		state.data[legTypeName as keyof ILegData] = [];
	}

	switch (leg.type) {
		case LegType.Collection:
		case LegType.Delivery:
		case LegType.Segment:
		case LegType.CollectDeliver:
		case LegType.ContainerDropOff:
		case LegType.ContainerPickUp:
		case LegType.ContainerGoodsCollection:
		case LegType.ContainerGoodsDelivery:
		case LegType.LoadAndLash:
		case LegType.Devan:
			updateArray(
				state.data[legTypeName as keyof ILegData] as IOperationLeg[],
				{
					...(leg as IOperationLeg),
					collectionLocation: leg.collectionLocation && {
						...leg.collectionLocation,
						nameAndAddress: `${leg.collectionLocation.name} - ${leg.collectionLocation.address}`,
					},
					deliveryLocation: leg.deliveryLocation && {
						...leg.deliveryLocation,
						nameAndAddress: `${leg.deliveryLocation.name} - ${leg.deliveryLocation.address}`,
					},
				},
				insertType,
			);
			break;
		case LegType.ClearCustoms:
			updateArray(
				state.data[legTypeName as keyof ILegData] as IClearCustomsLeg[],
				leg as IClearCustomsLeg,
				insertType,
			);
			break;
		case LegType.Storage:
			updateArray(
				state.data[legTypeName as keyof ILegData] as IStorageLeg[],
				leg as IStorageLeg,
				insertType,
			);
			break;
		case LegType.Ferry:
			updateArray(
				state.data[legTypeName as keyof ILegData] as IFerryLeg[],
				leg as IFerryLeg,
				insertType,
			);
			break;
		case LegType.Blank:
			updateArray(
				state.data[legTypeName as keyof ILegData] as IBlankLeg[],
				leg as IBlankLeg,
				insertType,
			);
			break;
		default:
			break;
	}
};
