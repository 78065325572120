import { DrawerItem } from "@progress/kendo-react-layout";
import cls from "classnames";
import type { CelerumDrawerItemProps } from "../../../../../../../models/src/lib/interfaces/celerum-drawer-item-props.interface";
import commonStyles from "../custom-drawer-item/custom-drawer-item.module.scss";
import styles from "./logo-drawer-item.module.scss";

export const LogoDrawerItem = ({
	logo,
	navbarExpanded,
	id,
	onSelect,
	route,
	title,
}: CelerumDrawerItemProps) => {
	const computedStyle = cls(
		commonStyles.drawerItem,
		navbarExpanded ? commonStyles.expanded : "",
	);

	return (
		<DrawerItem
			className={computedStyle}
			id={id}
			onSelect={onSelect}
			route={route}
		>
			<div
				className={navbarExpanded ? styles?.logoHover : styles?.logo}
				title={title}
			>
				<span className={logo} />
			</div>
		</DrawerItem>
	);
};
