import { useQuery } from "@tanstack/react-query";
import type { GoodFormObject } from "../Good";
import {
	jobApi,
	toasted,
	useLookupConstraints,
	useLookupLocations,
} from "../helpers";
import { GoodForm } from "./GoodForm";

export type GoodFormWithDTOProps = {
	id?: number;
	jobId?: number;
	onSubmit?: (data: GoodFormObject) => void;
};
export const GoodFormWithDTO = ({
	id,
	jobId,
	onSubmit,
}: GoodFormWithDTOProps) => {
	const _good = useQuery({
		queryKey: ["jobApi.bff.bffGoodFormDataDetail", id, jobId],
		queryFn: async () => {
			if (jobId) {
				return {
					constraintIds: [],
					jobId: jobId,
					revenue: 0,
					collectionDate: new Date(),
					deliveryDate: new Date(),
				} satisfies GoodFormObject;
			}
			const x = await jobApi.bff.bffGoodFormDataDetail(Number(id));
			return x.data;
		},
		refetchOnWindowFocus: false,
	});
	const lookupConstraints = useLookupConstraints();
	const lookupLocations = useLookupLocations();

	if (!_good.isFetchedAfterMount) {
		return <div>Loading...</div>;
	}

	return (
		<GoodForm
			defaultValues={{
				..._good.data,
				collectionDate: _good.data?.collectionDate
					? new Date(_good.data.collectionDate)
					: new Date(),
				deliveryDate: _good.data?.deliveryDate
					? new Date(_good.data.deliveryDate)
					: new Date(),
			}}
			onSubmit={async (data: GoodFormObject) => {
				toasted(
					jobApi.bff
						.bffGoodFormDataCreate({
							...data,
							collectionDate: data.collectionDate?.toISOString(),
							deliveryDate: data.deliveryDate?.toISOString(),
							constraintIds: data.constraintIds ?? [],
						})
						.then(() => onSubmit?.(data)),
					"Submitting Goods",
				);
			}}
			lConstraints={lookupConstraints}
			lLocations={lookupLocations}
		/>
	);
};
