import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type { ITrailerType } from "../../../../common/models/src/lib/interfaces/trailer-type.interface";
import { jobApi } from "../../../../common/utils/src/lib/axios.utils";
import { buildQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";

export const fetchTrailerTypes = async (params: CelerumQueryParams) => {
	const query = buildQueryString(params);

	const response = await jobApi.get<CelerumResponse>(`/TrailerType${query}`);
	return response.data;
};

export const createTrailerType = async (trailerType: ITrailerType) => {
	const response = await jobApi.post<ITrailerType>("/TrailerType", trailerType);
	return response.data;
};

export const updateTrailerType = async (trailerType: ITrailerType) => {
	const response = await jobApi.put<ITrailerType>("/TrailerType", trailerType);
	return response.data;
};

export const deleteTrailerType = async (id: number) => {
	await jobApi.delete<number>(`/TrailerType/${id}`);
	return id;
};
