export enum LegType {
	Invalid = 0,
	Storage = 1,
	Ferry = 2,
	ClearCustoms = 3,
	Collection = 4,
	Segment = 5,
	Delivery = 6,
	CollectDeliver = 7,
	Blank = 8,
	ContainerPickUp = 9,
	ContainerDropOff = 10,
	ContainerGoodsCollection = 11,
	ContainerGoodsDelivery = 12,
	LoadAndLash = 13,
	Devan = 14,
}

export enum LegStatus {
	New = 1,
	Planned = 2,
	Accepted = 3,
	InStartPosition = 4,
	Underway = 5,
	InEndPosition = 6,
	SignedOff = 7,
	NotStarted = 8,
	DocumentsReady = 9,
	Clearing = 10,
	CustomsCleared = 11,
	WorkFinished = 12,
	BookingInProgress = 13,
	Booked = 14,
	Paused = 15,
	Cancelled = 16,
	Archived = 17,
	PartOfALoad = 18,
	ReadyForWork = 19,
}
