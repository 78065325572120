import type { SelectionRange } from "@progress/kendo-react-dateinputs";
import { useRef } from "react";
import type { IDateFilter } from "../../../../../../models/src/lib/interfaces/filter.interface";
import { CelerumDropdownCategory } from "../../celerum-dropdown-category/celerum-dropdown-category.components";
import { CelerumPopupDateRangeSelection } from "../../celerum-popup/components/celerum-popup-date-range-selection/celerum-popup-date-range-selection.component";
import type { DateRangeFilterItem } from "../interfaces/filters.interface";

interface CelerumDropDownWithDateRangePopupProps {
	onClick: () => void;
	onClose: () => void;
	filter: DateRangeFilterItem;
	filtered: boolean;
	show: boolean | undefined;
	handleSubmit: (date: SelectionRange | null) => void;
	initialRange: IDateFilter[];
}

export const CelerumDropDownWithDateRangePopup = ({
	onClick,
	onClose,
	filtered,
	filter,
	show,
	handleSubmit,
	initialRange,
}: CelerumDropDownWithDateRangePopupProps) => {
	const anchor = useRef<HTMLDivElement | null>(null);

	return (
		<>
			<CelerumDropdownCategory
				onClick={onClick}
				name={filter.name}
				anchor={anchor}
				filtered={filtered}
				show={show}
			/>
			<CelerumPopupDateRangeSelection
				anchor={anchor}
				handleSubmit={handleSubmit}
				onClose={onClose}
				title={filter.name}
				show={show}
				initialRange={initialRange}
			/>
		</>
	);
};
