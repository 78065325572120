import { LegStatus } from "../../../../../common/models/src/lib/enums/leg.enum";
import type { ILegSummary } from "../../../../../common/models/src/lib/interfaces/leg.interface";

export const canDelete = (item: unknown) => {
	const leg = item as ILegSummary;
	return leg.status === LegStatus.New;
};

export const canCancel = (item: unknown) => {
	const leg = item as ILegSummary;

	return leg.status !== LegStatus.New && leg.status !== LegStatus.PartOfALoad;
};

export const canPause = () => false;

export const canDuplicate = () => false;

export const canResume = (item: unknown) => {
	const leg = item as ILegSummary;

	return leg.status === LegStatus.Paused;
};
