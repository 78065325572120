import { SubcontractorStatus } from "../enums/subcontractor.enum";

export const SUBCONTRACTORS_FEATURE_KEY = "subcontractors";
export const SUBCONTRACTORS_PAGE_SIZE = 50;

export const NEW_SUBCONTRACTOR_STATE = {
	id: -1,
	name: "",
	contactNumber: null,
	email: null,
	notes: null,
	status: SubcontractorStatus.Active,
	truckTypes: [],
	trailerTypes: [],
	constraints: [],
};
