import { Button } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { eyeIcon } from "@progress/kendo-svg-icons";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { DFlex } from "../display/DFlex";
import { jobApi, toasted } from "../helpers";
import { useDialog } from "../helpersReact";

type LegNoteProps = {
	data: {
		id: number;
		name: string;
	}[];
	onSubmit: (id: number) => void;
};
const LegNoteDialog = ({ data, onSubmit }: LegNoteProps) => {
	const [selectedId, setSelectedId] = useState<number>();
	return (
		<DFlex column style={{ minWidth: "300px" }}>
			<DFlex column gap="8px">
				<h3>Please select a good:</h3>
				<RadioGroup
					data={data.map((x) => ({
						label: x.name,
						value: x.id,
					}))}
					onChange={(x) => setSelectedId(x.value)}
				/>
			</DFlex>
			<DFlex flexEnd>
				<Button
					onClick={selectedId ? () => onSubmit(selectedId) : undefined}
					themeColor="primary"
					disabled={!selectedId}
				>
					<SvgIcon icon={eyeIcon} />
					View document
				</Button>
			</DFlex>
		</DFlex>
	);
};

type LegNoteDialogWithDTOProps = {
	legId: number;
	isCollectionNote?: boolean;
	onSuccess?: () => void;
};
const LegNoteDialogWithDTO = ({
	legId,
	isCollectionNote,
	onSuccess,
}: LegNoteDialogWithDTOProps) => {
	const data = useQuery({
		queryKey: ["leg-note", legId],
		queryFn: () =>
			jobApi.bff.bffLegNoteDataCreate({ legId }).then((x) => x.data),
		initialData: [],
	});
	return (
		<LegNoteDialog
			data={data.data}
			onSubmit={async (goodId) => {
				if (isCollectionNote) {
					await toasted(
						jobApi.leg
							.legReportCollectionNoteDetail(legId, goodId, {
								format: "blob",
							})
							.then((x) => window.open(URL.createObjectURL(x.data), "_blank"))
							.then(onSuccess),
						"Generating collection note",
					);
				} else {
					await toasted(
						jobApi.leg
							.legReportDeliveryTicketDetail(legId, goodId, {
								format: "blob",
							})
							.then((x) => window.open(URL.createObjectURL(x.data), "_blank"))
							.then(onSuccess),
						"Generating delivery note",
					);
				}
			}}
		/>
	);
};

export const useLegNoteDialog = () => {
	const [props, setProps] = useState<LegNoteDialogWithDTOProps>();

	const [_toggleDialog, dialog] = useDialog(
		props && (
			<LegNoteDialogWithDTO {...props} onSuccess={() => _toggleDialog(false)} />
		),
		props?.isCollectionNote ? "Collection Note" : "Delivery Note",
	);

	const toggleDialog = (props?: LegNoteDialogWithDTOProps) => {
		setProps(props);
		if (!props) return _toggleDialog(false);
		_toggleDialog(true);
	};

	return [toggleDialog, dialog] as const;
};
