import type {
	MenuItemModel,
	MenuSelectEvent,
} from "@progress/kendo-react-layout";
import { CommandCellType } from "../../../../../../../models/src/lib/enums/command-cell.enum";
import {
	CelerumButtonDeleteGrid,
	CelerumButtonEditGrid,
	CelerumMoreDetailsButtonGrid,
	CelerumViewButtonGrid,
	CelerumViewDocumentsButtonGrid,
} from "../../../celerum-buttons/celerum-buttons.component";
import { CelerumSkeleton } from "../../../celerum-skeleton/celerum-skeleton.component";
import styles from "./command-cell.module.scss";

interface CommandCellProps {
	// biome-ignore lint/suspicious/noExplicitAny: can't be bothered, really
	dataItem: any;
	commandCellType: CommandCellType;
	loading: boolean;
	canDelete: boolean;
	// biome-ignore lint/suspicious/noExplicitAny: can't be bothered, really
	handleNavigate?: ((id: any | undefined) => void) | undefined;
	navigateItem?: string;
	actionField?: string;
	// biome-ignore lint/suspicious/noExplicitAny: can't be bothered, really
	handleDelete?: (dateItem: any) => void;
	// biome-ignore lint/suspicious/noExplicitAny: can't be bothered, really
	handleUpdate?: (dateItem: any) => void;
	handleSelect?: (action: MenuSelectEvent, id: number) => void;
	// biome-ignore lint/suspicious/noExplicitAny: can't be bothered, really
	openDocumentsModal?: (dateItem: any) => void;
	items?: MenuItemModel[];
}

export const CommandCell = ({
	dataItem,
	loading,
	commandCellType,
	handleUpdate,
	handleDelete,
	handleNavigate,
	actionField,
	navigateItem,
	handleSelect,
	openDocumentsModal,
	items,
	canDelete,
}: CommandCellProps) => {
	if (loading) {
		return (
			<td>
				<CelerumSkeleton />
			</td>
		);
	}

	const handleOnSelect = (e: MenuSelectEvent) => {
		if (actionField) {
			handleSelect?.(e, dataItem[actionField]);
		} else {
			handleSelect?.(e, dataItem.id);
		}
	};

	const renderElements = () => {
		switch (commandCellType) {
			case CommandCellType.NavigateViewMore:
				return (
					<>
						<CelerumViewButtonGrid
							title="View"
							onClick={() => {
								if (handleNavigate) {
									handleNavigate(
										navigateItem ? dataItem[navigateItem] : dataItem.id,
									);
								}
							}}
						/>
						<CelerumMoreDetailsButtonGrid
							title="More details"
							items={items ?? []}
							handleOnSelect={handleOnSelect}
						/>
					</>
				);
			default:
				return (
					<>
						{openDocumentsModal && (
							<CelerumViewDocumentsButtonGrid
								title="View Documents"
								onClick={() => {
									openDocumentsModal(dataItem);
								}}
							/>
						)}
						<CelerumButtonEditGrid
							title="Edit"
							onClick={() => {
								handleUpdate?.(dataItem);
							}}
						/>
						{canDelete && (
							<CelerumButtonDeleteGrid
								title="Remove"
								onClick={() => {
									handleDelete?.(dataItem);
								}}
							/>
						)}
					</>
				);
		}
	};

	return (
		<td className={styles.container}>
			<div className={styles.commandCellContainer}>{renderElements()}</div>
		</td>
	);
};
