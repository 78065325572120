import { useState } from "react";
import {
	JobFormWithDTO,
	type JobFormWithDTOProps,
} from "../forms/JobFormWithDTO";
import { useDialog } from "../helpersReact";

export const useJobFormDialog = (
	showLocationForm?: (callback: (value: number) => void) => void,
) => {
	const [props, setProps] = useState<JobFormWithDTOProps>();
	const [_toggleDialog, dialog] = useDialog(
		<JobFormWithDTO
			{...props}
			showLocationForm={props?.showLocationForm ?? showLocationForm}
		/>,
		props?.id ? (props.isDuplicate ? "Duplicate Job" : "Edit Job") : "New Job",
	);

	const toggleDialog = (props?: JobFormWithDTOProps) => {
		setProps(props);
		_toggleDialog(props != null);
	};

	return [toggleDialog, dialog] as const;
};
