import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import { JOB_TYPES_FEATURE_KEY } from "../../../../common/models/src/lib/constants/job-types.constants";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	IJobType,
	JobTypesState,
} from "../../../../common/models/src/lib/interfaces/job-type.interface";
import { fetchJobTypes } from "./job-types-data-access";

const initialStateJobTypes: JobTypesState = {
	data: [],
	total: 0,
	aggregateResults: null,
	loading: false,
	errors: null,
};

export const fetchJobTypesAction = createAsyncThunk(
	"jobTypes/fetchJobTypes",
	async () => {
		return fetchJobTypes();
	},
);

const jobTypesSlice = createSlice({
	name: JOB_TYPES_FEATURE_KEY,
	initialState: initialStateJobTypes,
	reducers: {},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchJobTypesAction.pending, (state: JobTypesState) => {
				state.loading = true;
			})
			/** Fulfilled */
			.addCase(
				fetchJobTypesAction.fulfilled,
				(state: JobTypesState, action: PayloadAction<CelerumResponse>) => {
					const { data } = action.payload;
					state.data = data as IJobType[];
					state.loading = false;
				},
			)
			/** Rejected */
			.addCase(fetchJobTypesAction.rejected, (state: JobTypesState) => {
				state.loading = false;
			});
	},
});

/** Reducer */
export const jobTypesReducer = jobTypesSlice.reducer;
