import { type SVGIcon, SvgIcon } from "@progress/kendo-react-common";
import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import type { ReactNode } from "react";

type InformationCardProps = {
	icon: SVGIcon;
	title: string;
	value: ReactNode;
};
export const JobDetailsInformationCard = ({
	icon,
	title,
	value,
}: InformationCardProps) => (
	<Card>
		<CardHeader
			style={{
				display: "flex",
				padding: "4px",
				gap: "4px",
				color: "gray",
				alignItems: "center",
			}}
		>
			<SvgIcon icon={icon} />
			{title}
		</CardHeader>
		<CardBody style={{ padding: "4px", fontWeight: "bold" }}>{value}</CardBody>
	</Card>
);
