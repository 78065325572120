import { Tooltip } from "@progress/kendo-react-tooltip";
import type { ReactNode } from "react";
import styles from "./celerum-tooltip.module.scss";

interface CelerumTooltipProps {
	children: ReactNode;
	title: string;
	position?: "top" | "bottom" | "left" | "right";
}

export const CelerumTooltip = ({
	children,
	title,
	position = "bottom",
}: CelerumTooltipProps) => (
	<Tooltip
		anchorElement="target"
		position={position}
		parentTitle={true}
		openDelay={300}
	>
		<div className={styles.container} title={title}>
			{children}
		</div>
	</Tooltip>
);
