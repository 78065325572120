import { LoadStatus } from "../../../../../common/models/src/lib/enums/load.enum";
import type { ILoad } from "../../../../../common/models/src/lib/interfaces/load.interface";

export const canDelete = (item: unknown) => {
	const load = item as ILoad;
	return load.status === LoadStatus.New;
};

export const canCancel = (item: unknown) => {
	const load = item as ILoad;
	switch (load.status) {
		case LoadStatus.New:
		case LoadStatus.Cancelled:
		case LoadStatus.Underway:
			return false;
		default:
			return true;
	}
};

export const canPause = (item: unknown) => {
	const load = item as ILoad;
	return load.status !== LoadStatus.Paused;
};

export const canDuplicate = () => {
	return false;
};

export const canResume = (item: unknown) => {
	const load = item as ILoad;
	return load.status === LoadStatus.Paused;
};

export const canGenerateManifest = () => true;

export const canPrintAllDocuments = () => true;

export const canDownloadDocuments = () => true;
