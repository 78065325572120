import { type InferType, number, object, string } from "yup";

export const LocationSchema = object({
	id: number().label("ID"),
	customerId: number().label("Customer"),
	name: string().required().label("Name"),
	address: string().required().label("Address"),
	latitude: number().required().label("Latitude"),
	longitude: number().required().label("Longitude"),
	notes: string().label("Notes"),
});
export type LocationFormObject = InferType<typeof LocationSchema>;
