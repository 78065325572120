import { useState } from "react";

export const useOldLocalStorage = <T>(
	key: string,
	initialItem: T,
): [T, (newItem: T) => void, () => void] => {
	const initialStateFromStorageString = localStorage.getItem(key);

	let initialState = null;

	if (!initialStateFromStorageString) {
		localStorage.setItem(key, JSON.stringify(initialItem));
		initialState = initialItem as T;
	} else {
		initialState = JSON.parse(initialStateFromStorageString) as T;
	}

	const [state, setState] = useState(initialState);

	const setLocalStorageState = (newItem: T) => {
		setState(newItem);
		localStorage.setItem(key, JSON.stringify(newItem));
	};

	const removeLocalStorageState = () => {
		localStorage.removeItem(key);
	};

	return [state, setLocalStorageState, removeLocalStorageState];
};
