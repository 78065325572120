import { SvgIcon } from "@progress/kendo-react-common";
import cls from "classnames";
import {
	arrowIcon,
	deliveryIconRounded,
} from "../../../../../../common/models/src/lib/constants/icon.constants";
import { NOT_AVAILABLE } from "../../../../../../common/models/src/lib/constants/messages.constants";
import type { ILoadDetails } from "../../../../../../common/models/src/lib/interfaces/load.interface";
import { CelerumAddressCard } from "../../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { formatDate } from "../../../../../../common/utils/src/lib/helpers/date.helpers";
import { useScrollbarDetection } from "../../../../../../common/utils/src/lib/hooks/use-scrollbar-detection.hook";
import { LoadInformationGrid } from "../load-information-grid/load-information-grid.component";
import styles from "./load-information-card-container.module.scss";

interface LoadInformationCardContainerProps {
	loadDetails: ILoadDetails;
}

export const LoadInformationCardContainer = ({
	loadDetails,
}: LoadInformationCardContainerProps) => {
	const { startLocation, startDate, endLocation, endDate, notes } = loadDetails;

	const { containerRef, hasScrollBar } = useScrollbarDetection();

	return (
		<div
			className={cls(
				styles.container,
				hasScrollBar ? styles.scrollPadding : styles.noScroll,
			)}
			ref={containerRef}
		>
			<LoadInformationGrid loadDetails={loadDetails} />
			<div className={styles.secondRow}>
				<div className={styles.paragraphDescriptionContainer}>
					<p className={styles.paragraphDescription}>
						{notes ? notes : "No notes added yet!"}
					</p>
				</div>
				<div className={styles.addressCardContainer}>
					<CelerumAddressCard
						title="Start Location"
						name={startLocation?.name ?? NOT_AVAILABLE}
						address={startLocation?.address ?? NOT_AVAILABLE}
						date={startDate ? formatDate(new Date(startDate)) : NOT_AVAILABLE}
					/>
					<SvgIcon width={50} icon={arrowIcon} />
					<CelerumAddressCard
						title="End Location"
						name={endLocation?.name ?? NOT_AVAILABLE}
						address={endLocation?.address ?? NOT_AVAILABLE}
						date={endDate ? formatDate(new Date(endDate)) : NOT_AVAILABLE}
						icon={deliveryIconRounded}
					/>
				</div>
			</div>
		</div>
	);
};
