import { ErrorCode } from "../../../../models/src/lib/enums/error-codes.enum";

export const generateErrorMessage = (code: ErrorCode) => {
	switch (code) {
		case ErrorCode.INVALID_CREDENTIALS:
			return "Invalid credentials!";
		case ErrorCode.INVALID_MODEL:
			return "This email is already taken!";
		default:
			return "Something went wrong!";
	}
};

export const generateErrorCode = (code: string | undefined) => {
	return !Number.isNaN(Number(code))
		? (Number(code) as ErrorCode)
		: ErrorCode.DEFAULT;
};
