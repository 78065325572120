import { truckIcon } from "../../../../../../../../common/models/src/lib/constants/icon.constants";
import { SUPPLIER_INVOICES } from "../../../../../../../../common/models/src/lib/constants/invoice.constants";
import {
	ABSENT,
	NOT_AVAILABLE,
} from "../../../../../../../../common/models/src/lib/constants/messages.constants";
import type { IBlankLeg } from "../../../../../../../../common/models/src/lib/interfaces/leg.interface";
import { formatDate } from "../../../../../../../../common/utils/src/lib/helpers/date.helpers";
import styles from "../../leg-accordion-content.module.scss";
import { TitleSection } from "../title-section/title-section.component";

export const BlankInformation = ({ leg }: { leg: IBlankLeg }) => {
	return (
		<div className={styles.container}>
			<div className={styles.wrapper} style={{ alignItems: "flex-start" }}>
				<div className={styles.wrapper} style={{ gap: "32px" }}>
					<TitleSection icon={truckIcon} title="Leg type" value="Blank" />
					<TitleSection
						icon={truckIcon}
						title="Supplier Invoice"
						value={
							leg?.supplierInvoice !== undefined
								? (SUPPLIER_INVOICES[leg.supplierInvoice]?.name ?? ABSENT)
								: ABSENT
						}
					/>
					<TitleSection
						icon={truckIcon}
						title="Supplier Invoice Number"
						value={
							leg?.supplierInvoiceNumber && leg.supplierInvoiceNumber !== ""
								? leg?.supplierInvoiceNumber
								: NOT_AVAILABLE
						}
					/>
					<TitleSection
						icon={truckIcon}
						title="Supplier Invoice Date"
						value={
							leg?.supplierInvoiceDate
								? formatDate(new Date(leg.supplierInvoiceDate))
								: NOT_AVAILABLE
						}
					/>
				</div>
			</div>
		</div>
	);
};
