import {
	type InferType,
	array,
	date,
	mixed,
	number,
	object,
	string,
} from "yup";
import type { LoadActions } from "./api/JobApi";
import { noInvalidDate, noNaN } from "./helpers";

export const LoadSchema = object({
	id: number().transform(noNaN).label("ID"),
	assignedTo: string().label("Assigned To"),
	endDate: date().transform(noInvalidDate).label("End Date"),
	endLocationId: number().label("End Location"),
	startDate: date().required().transform(noInvalidDate).label("Start Date"),
	startLocationId: number().required().label("Start Location"),
	loadTypeId: number().label("Load Type").required(),
	trailerId: number().label("Trailer"),
	subcontractorId: number().label("Subcontractor"),
	notes: string().label("Notes"),
	documents: array()
		.of(mixed<File>().required().label("Document"))
		.label("Documents"), // not part of the post
});

export type LoadFormObject = InferType<typeof LoadSchema>;

export type LoadGridObject = LoadFormObject & {
	id: number;
	uniqueId: string;
	status: number;
	statusString: string;
	assignedToString: string;
	endDateString: string;
	endLocationString: string;
	startDateString: string;
	startLocationString: string;
	loadTypeString: string;
	trailerString: string;
	truckString: string;
	driverString: string;
	subcontractorName: string;
	profit: number;
	profitString: string;
	cost?: number;
	sharedCost?: number;
	currencyCode: string | undefined;
	revenue?: number;
	actions: LoadActions;
};
