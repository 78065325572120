import { JobStatus } from "../../../../common/models/src/lib/enums/job.enum";
import type { TemplateType } from "../../../../common/models/src/lib/enums/template.enum";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	GenerateInvoiceDto,
	IChangeInvoiceStatusRequestDto,
	ICheckInvoiceRequestDto,
	ICheckInvoiceRequestDtos,
	IChecklistItem,
	IMarkInvoiceAsSentRequestDto,
} from "../../../../common/models/src/lib/interfaces/invoice.interface";
import {
	jobApi,
	reportApi,
} from "../../../../common/utils/src/lib/axios.utils";
import { buildQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";

export const fetchInvoiceableJobs = async (params: CelerumQueryParams) => {
	const { page, pageSize } = params;
	const query = buildQueryString(params);

	const response = await jobApi.get<CelerumResponse>(`/Invoice${query}`);

	return {
		...response.data,
		page,
		pageSize,
	};
};

export const fetchInvoiceChecklist = async (id: number) => {
	const response = await jobApi.get(`/Job/${id}/checklists`);
	return response.data;
};

export const updateInvoiceChecklistState = async (
	id: number,
	checklist: IChecklistItem[],
) => {
	await jobApi.post(`/Job/${id}/checklists`, checklist);
	return checklist;
};

export const markInvoiceableJobAsChecked = async (
	data: ICheckInvoiceRequestDto,
) => {
	const { id, status } = data;

	await jobApi.post(`/Job/${id}/change-status/${status}`);
	return id;
};

export const changeInvoiceStatus = async (
	data: IChangeInvoiceStatusRequestDto,
) => {
	const { id, status } = data;

	await jobApi.post(`/Job/${id}/change-status/${status}`);
	return id;
};

export const markInvoiceableJobsAsChecked = async (
	data: ICheckInvoiceRequestDtos,
) => {
	const { ids, status } = data;

	await jobApi.post(`/Job/${ids.join(",")}/change-all-status/${status}`);
	return ids;
};

export const markInvoiceableJobAsSent = async (
	data: IMarkInvoiceAsSentRequestDto,
) => {
	const { id, status } = data;

	await jobApi.post(`/Job/${id}/change-status/${status}`);
	return id;
};

export const sendInvoiceableJobToReview = async (id: number) => {
	await jobApi.post(`/Job/${id}/change-status/${JobStatus.COMPLETED}`);
	return id;
};

export const reInvoiceJob = async (id: number) => {
	await jobApi.post(
		`/Job/${id}/change-status/${JobStatus.READY_FOR_REINVOICE}`,
	);
	return id;
};

export const getPreview = async (id: number) => {
	const response = await jobApi.get<Blob>(`/Invoice/report/${id}/invoice`, {
		responseType: "blob",
	});
	return response.data;
};

export const fetchAllChecklists = async () => {
	const response = await jobApi.get("/Checklist");
	return response.data.data;
};

export const fetchAllTemplates = async (type: TemplateType) => {
	const response = await reportApi.get(`/template/v1/${type}`);
	return response.data;
};

export const createInvoice = async (dto: GenerateInvoiceDto) => {
	const response = await jobApi.post("/Invoice", dto);
	return response.data;
};
