import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { type LocationFormObject, LocationSchema } from "../Location";
import { DFlex } from "../display/DFlex";
import {
	GenericForm,
	IMap,
	ISelect,
	IText,
	ITextArea,
	type LoadOptionsFn,
} from "./GenericForm";

type LocationFormProps = {
	defaultValues?: Partial<LocationFormObject>;
	onSubmit: (data: LocationFormObject) => void;
	lCustomers: LoadOptionsFn;
};
export const LocationForm = ({
	defaultValues,
	onSubmit,
	lCustomers,
}: LocationFormProps) => {
	const form = useForm<LocationFormObject>({
		resolver: yupResolver(LocationSchema),
		defaultValues,
	});
	return (
		<GenericForm
			form={form}
			schema={LocationSchema}
			onSubmit={async (x) => onSubmit(x)}
		>
			<DFlex>
				<div>
					<IText n="name" />
					<ITextArea n="address" />
				</div>
				<div>
					<ISelect n="customerId" l={lCustomers} />
					<ITextArea n="notes" />
				</div>
			</DFlex>
			<IMap longitude="longitude" latitude="latitude" address="address" />
		</GenericForm>
	);
};
