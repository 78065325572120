import { SvgIcon } from "@progress/kendo-react-common";
import { Notification } from "@progress/kendo-react-notification";
import {
	errorIcon,
	infoIcon,
	successIcon,
	warningIcon,
} from "../../../../../models/src/lib/constants/icon.constants";

interface CelerumNotificationProps {
	type: "success" | "info" | "warning" | "error";
	message: string;
}

export const CelerumNotification = ({
	type,
	message,
}: CelerumNotificationProps) => {
	const renderIcon = () => {
		switch (type) {
			case "success":
				return successIcon;
			case "warning":
				return warningIcon;
			case "error":
				return errorIcon;
			case "info":
				return infoIcon;
			default:
				return infoIcon;
		}
	};

	return (
		<Notification type={{ style: type }}>
			<SvgIcon icon={renderIcon()} />
			<span>{message}</span>
		</Notification>
	);
};
