import styles from "./inputtext-cell.module.scss";

interface InputtextCellProps {
	value: string;
	onChange: (value: string) => void;
}

export const InputtextCell = ({ value, onChange }: InputtextCellProps) => {
	const handleChange = (newValue: string) => {
		if (newValue === value) return;
		onChange(newValue);
	};
	return (
		<td>
			<div className={styles.container}>
				<input
					className={styles.inputText}
					type="text"
					defaultValue={value}
					onBlur={(e) => handleChange(e.currentTarget.value)}
					// make it work when press enter
					onKeyDown={(e) => {
						if (e.key !== "Enter") return;
						handleChange(e.currentTarget.value);
					}}
				/>
			</div>
		</td>
	);
};
