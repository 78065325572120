import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type {
	CelerumResponse,
	CelerumSummaryResponse,
} from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type { IGenerateCollectionNoteRequestDto } from "../../../../common/models/src/lib/interfaces/leg.interface";
import type {
	AnyLeg,
	AnyLegRequestDto,
} from "../../../../common/models/src/lib/types/leg.type";
import { jobApi } from "../../../../common/utils/src/lib/axios.utils";
import { buildQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";

export const fetchLegsByJobId = async (jobId: number) => {
	const response = await jobApi.get<CelerumSummaryResponse>(
		`/Leg/job/${jobId}/grouped`,
	);
	return response.data;
};

export const fetchLegSummary = async (params: CelerumQueryParams) => {
	const { page, pageSize } = params;
	const query = buildQueryString(params);

	const response = await jobApi.get<CelerumResponse>(`/Leg${query}`);
	return {
		...response.data,
		page,
		pageSize,
	};
};

export const fetchLegsByLoadId = async (loadId: number) => {
	const response = await jobApi.get<CelerumSummaryResponse>(
		`/Leg/load/${loadId}/grouped`,
	);
	return response.data;
};

export const createLeg = async (leg: AnyLegRequestDto) => {
	const response = await jobApi.post<AnyLeg>("/Leg", leg);
	return response.data;
};

export const updateLeg = async (leg: AnyLegRequestDto) => {
	const response = await jobApi.put<AnyLeg>("/Leg", leg);
	return response.data;
};

export const updateLegSupplierInvoiceNumber = async (
	legId: number,
	supplierInvoiceNumber: string,
	supplierInvoiceDate: string,
) => {
	const response = await jobApi.put<void>(
		`/Leg/${legId}/supplier-invoice-number/${supplierInvoiceNumber}/${supplierInvoiceDate}`,
	);
	return response.data;
};

export const changeLegStatus = async (legId: number, status: number) => {
	await jobApi.post(`/Leg/${legId}/change-status?status=${status}`);
	return {
		legId,
		status,
	};
};

export const deleteLeg = async (legId: number) => {
	await jobApi.delete<number>(`/Leg/${legId}`);
	return legId;
};

export const generateCollectionNote = async (
	dto: IGenerateCollectionNoteRequestDto,
) => {
	const response = await jobApi.get<Blob>(
		`/Leg/report/${dto.legId}/collectionNote/${dto.goodsId}`,
		{
			responseType: "blob",
		},
	);
	return response.data;
};

export const generateDeliveryTicket = async (
	dto: IGenerateCollectionNoteRequestDto,
) => {
	const response = await jobApi.get<Blob>(
		`/Leg/report/${dto.legId}/deliveryTicket/${dto.goodsId}`,
		{
			responseType: "blob",
		},
	);
	return response.data;
};

export const generateSubcontractorOrder = async (
	dto: IGenerateCollectionNoteRequestDto,
) => {
	const response = await jobApi.get<Blob>(
		`/Leg/report/${dto.legId}/subcontractorOrder`,
		{
			responseType: "blob",
		},
	);
	return response.data;
};
