import { DrawerItemType } from "../../../../../../../models/src/lib/enums/drawer-item-types.enum";
import type { CelerumDrawerItemProps } from "../../../../../../../models/src/lib/interfaces/celerum-drawer-item-props.interface";
import { CategoryDrawerItem } from "../category-drawer-item/category-drawer-item.component";
import { LinkDrawerItem } from "../link-drawer-item/link-drawer-item.component";
import { LogoDrawerItem } from "../logo-drawer-item/logo-drawer-item.component";
import { LogoutDrawerItem } from "../logout-drawer-item/logout-drawer-item.component";
import { OpenCloseDrawerItem } from "../open-close-drawer-item/open-close-drawer-item.component";
import { ProfileDrawerItem } from "../profile-drawer-item/profile-drawer-item.component";

export const CustomDrawerItem = ({
	visible,
	...others
}: CelerumDrawerItemProps) => {
	if (!visible) {
		return null;
	}

	switch (others.itemType) {
		case DrawerItemType.Logo: {
			return <LogoDrawerItem {...others} title={document.lastModified} />;
		}
		case DrawerItemType.Category: {
			return <CategoryDrawerItem {...others} />;
		}
		case DrawerItemType.Link: {
			return <LinkDrawerItem {...others} />;
		}
		case DrawerItemType.Logout: {
			return <LogoutDrawerItem {...others} />;
		}
		case DrawerItemType.Profile: {
			return <ProfileDrawerItem {...others} />;
		}
		case DrawerItemType.OpenCloseIcon: {
			return <OpenCloseDrawerItem {...others} />;
		}
		default: {
			return null;
		}
	}
};
