import { SvgIcon } from "@progress/kendo-react-common";
import { DrawerItem } from "@progress/kendo-react-layout";
import cls from "classnames";
import type { CelerumDrawerItemProps } from "../../../../../../../models/src/lib/interfaces/celerum-drawer-item-props.interface";
import commonStyles from "../custom-drawer-item/custom-drawer-item.module.scss";

export const LogoutDrawerItem = ({
	svgIcon,
	navbarExpanded,
	logoutHandler,
	text,
}: CelerumDrawerItemProps) => {
	const computedStyle = cls(
		commonStyles.drawerItem,
		commonStyles.mainItem,
		navbarExpanded ? commonStyles.expanded : "",
	);

	return !navbarExpanded ? (
		<DrawerItem onClick={logoutHandler} className={computedStyle}>
			<SvgIcon
				width={25}
				className={commonStyles.svgIconStyle}
				icon={svgIcon}
			/>
		</DrawerItem>
	) : (
		<DrawerItem onClick={logoutHandler} className={computedStyle}>
			<SvgIcon
				width={20}
				className={commonStyles.svgIconStyle}
				icon={svgIcon}
			/>
			<span className="k-item-text">{text}</span>
		</DrawerItem>
	);
};
