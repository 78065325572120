export enum JobStatus {
	NEW = 1,
	PLANNED = 2,
	LOADED = 3,
	COMPLETED = 4,
	PAUSED = 5,
	CANCELLED = 6,
	ARCHIVED = 7,
	READY_FOR_INVOICE = 8,
	CHECKED = 9,
	INVOICE_GENERATED = 10,
	INVOICE_SENT = 11,
	INVOICE_FAILED = 12,
	READY_FOR_REINVOICE = 13,
	CHECKED_AGAIN = 14,
	REINVOICE_GENERATED = 15,
	REINVOICE_SENT = 16,
	SCHEDULED_FOR_INVOICE = 17,
}
