import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	GoodsQueryParams,
	IGoodsForLoad,
	IGoodsLoadDto,
	IGoodsRequestDto,
} from "../../../../common/models/src/lib/interfaces/goods.interface";
import { jobApi } from "../../../../common/utils/src/lib/axios.utils";
import { buildQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";

export const fetchGoodsByJobId = async (jobId: number) => {
	const response = await jobApi.get<CelerumResponse>(`Goods/Job/${jobId}`);
	return response.data;
};

export const deleteGoods = async (id: number) => {
	await jobApi.delete<number>(`Goods/${id}`);
	return id;
};

export const createGoods = async (goods: IGoodsRequestDto) => {
	const response = await jobApi.post("Goods", goods);
	return response.data;
};

export const updateGoods = async (goods: IGoodsRequestDto) => {
	const response = await jobApi.put("Goods", goods);
	return response.data;
};

export const fetchGoodsForLoad = async (params: GoodsQueryParams) => {
	const { page, pageSize } = params;
	const query = buildQueryString(params);

	const response = await jobApi.get<CelerumResponse>(`Goods/Load${query}`);

	return { ...response.data, page, pageSize };
};

export const addGoodsForLoad = async (
	goodsForLoad: IGoodsForLoad[],
	loadId: number | string,
) => {
	const response = await jobApi.post(`Goods/Load/${loadId}`, {
		goods: goodsForLoad,
	});
	return response.data;
};

export const fetchGoodsByLoadId = async (loadId: number | string) => {
	const response = await jobApi.get<CelerumResponse>(`Goods/Load/${loadId}`);
	return response.data;
};

export const deleteGoodsFromLoad = async (dto: IGoodsLoadDto) => {
	const { goodsId, loadId } = dto;

	await jobApi.delete<number>(`Goods/${goodsId}/load/${loadId}`);
	return goodsId;
};

export const generateCmr = async (goodsId: number) => {
	const response = await jobApi.get<Blob>(`Goods/report/${goodsId}/cmr`, {
		responseType: "blob",
	});
	return response.data;
};

export const generateCargoLabel = async (goodsId: number) => {
	const response = await jobApi.get<Blob>(
		`Goods/report/${goodsId}/cargoLabel`,
		{ responseType: "blob" },
	);
	return response.data;
};
