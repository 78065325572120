export const TRAILERS_FEATURE_KEY = "trailers";

export const TRAILERS_PAGE_SIZE = 50;

export const NEW_TRAILER_STATE = {
	id: -1,
	name: "",
	chassisNo: "",
	fleetNumber: "",
	ministryNumber: "",
	europeanRegistrationNumber: "",
	status: 0,
	nextMOTDate: undefined,
	nextInspectionDate: undefined,
	notes: "",
	documents: [],
	trailerType: {
		id: -1,
		name: "",
		vehicleType: "",
	},
	constraints: [],
};
