import { SvgIcon } from "@progress/kendo-react-common";
import { DrawerItem } from "@progress/kendo-react-layout";
import { chevronLeftIcon, chevronRightIcon } from "@progress/kendo-svg-icons";
import type { CelerumDrawerItemProps } from "../../../../../../../models/src/lib/interfaces/celerum-drawer-item-props.interface";
import styles from "./open-close-drawer-item.module.scss";

export const OpenCloseDrawerItem = ({
	navbarExpanded,
	setExpanded,
}: CelerumDrawerItemProps) =>
	!navbarExpanded ? (
		<DrawerItem
			className={styles.openCloseIconContainer}
			onClick={() => setExpanded(true)}
		>
			<SvgIcon
				width={12}
				icon={chevronRightIcon}
				className={styles.openCloseIcon}
			/>
		</DrawerItem>
	) : (
		<DrawerItem
			className={styles.openCloseIconContainer}
			onClick={() => setExpanded(false)}
		>
			<SvgIcon
				width={12}
				icon={chevronLeftIcon}
				className={styles.openCloseIcon}
			/>
		</DrawerItem>
	);
