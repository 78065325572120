import { SvgIcon } from "@progress/kendo-react-common";
import { Fragment, type ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { arrowBackIcon } from "../../../../../models/src/lib/constants/icon.constants";
import { NOT_AVAILABLE } from "../../../../../models/src/lib/constants/messages.constants";
import { CelerumSkeleton } from "../celerum-skeleton/celerum-skeleton.component";
import { CelerumStatus } from "../celerum-status/celerum-status.component";
import styles from "./celerum-details-header.module.scss";

interface CelerumDetailsHeaderProps {
	loading: boolean;
	title?: string | ReactNode;
	subtitle?: string | ReactNode;
	status?: {
		title: string;
		className: string;
	};
	children?: React.ReactNode;
}

export const CelerumDetailsHeader = ({
	loading,
	title,
	subtitle,
	status,
	children,
}: CelerumDetailsHeaderProps) => {
	const navigate = useNavigate();
	const location = useLocation();

	const previousRoute = location.pathname.split("/").slice(0, -1).join("/");
	const previousPath = location.state?.from;

	const handleBack = () => {
		if (previousPath) {
			navigate(previousPath);
		} else {
			navigate(previousRoute);
		}
	};

	return (
		<div className={styles.container}>
			{loading ? (
				<CelerumSkeleton />
			) : (
				<Fragment>
					<div className={styles.wrapper}>
						<span
							className={styles.wrapper__back}
							onClick={handleBack}
							onKeyUp={handleBack}
						>
							<SvgIcon width={20} icon={arrowBackIcon} />
						</span>
						{title && (
							<div className={styles.wrapper__header}>
								<span className={styles.wrapper__header__title}>{title}</span>
								<span className={styles.wrapper__header__subtitle}>
									{subtitle ?? NOT_AVAILABLE}
								</span>
							</div>
						)}
						{status && (
							<div className={styles.wrapper__status}>
								<CelerumStatus
									title={status.title}
									className={styles[status.className]}
								/>
							</div>
						)}
					</div>
					{children}
				</Fragment>
			)}
		</div>
	);
};
