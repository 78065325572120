export enum ModalType {
	Delete = "Delete",
	Warning = "Warning",
	Success = "Success",
	Error = "Error",
}

export enum ModalSize {
	Small = 400,
	Medium = 650,
	Large = 900,
	ExtraLarge = 1200,
}
