import type { MenuItemModel } from "@progress/kendo-react-layout";
import type { MoreOptions } from "../../../../models/src/lib/interfaces/more-options.interface";

export const handleOptions = (
	dataItem: unknown,
	handleMoreOptions: MoreOptions,
): MenuItemModel[] => {
	const options: MenuItemModel[] = [
		{ text: "Actions", disabled: true, cssClass: "separator" },
	];
	handleMoreOptions?.canPause?.(dataItem) && options.push({ text: "Pause" });
	handleMoreOptions?.canResume?.(dataItem) && options.push({ text: "Resume" });
	handleMoreOptions?.canDuplicate?.(dataItem) &&
		options.push({ text: "Duplicate" });
	handleMoreOptions?.canSendToInvoice?.(dataItem) &&
		options.push({ text: "Send to invoice" });
	handleMoreOptions?.canGenerateManifest?.(dataItem) &&
		options.push({ text: "Generate Manifest" });
	handleMoreOptions?.canForceComplete?.(dataItem) &&
		options.push({ text: "Force Complete" });
	handleMoreOptions?.canGenerateFileFront?.(dataItem) &&
		options.push({ text: "Generate File Front" });
	handleMoreOptions?.canPrintAllDocuments?.(dataItem) &&
		options.push({ text: "Print All Documents" });
	handleMoreOptions?.canDownloadDocuments?.(dataItem) &&
		options.push({ text: "Clear Customs Documents" });
	handleMoreOptions?.canCancel?.(dataItem) && options.push({ text: "Cancel" });
	handleMoreOptions?.canDelete?.(dataItem) &&
		options.push({ text: "Delete", cssClass: "red" });
	handleMoreOptions?.canPreviewInvoice?.(dataItem) &&
		options.push({ text: "Preview Invoice" });
	handleMoreOptions?.canSendForReview?.(dataItem) &&
		options.push({ text: "Send for Review" });
	handleMoreOptions?.canReInvoice?.(dataItem) &&
		options.push({ text: "Re-invoice" });
	handleMoreOptions?.canMarkAsSent?.(dataItem) &&
		options.push({ text: "Mark as Sent" });
	handleMoreOptions?.canSendToOperations?.(dataItem) &&
		options.push({ text: "Send to Operations" });
	handleMoreOptions?.canSendToInvoicingCheck?.(dataItem) &&
		options.push({ text: "Send to Invoicing Check" });
	handleMoreOptions?.canEditInvoice?.(dataItem) &&
		options.push({ text: "Edit" });
	return options;
};
