import cls from "classnames";
import styles from "./celerum-status.module.scss";

interface CelerumStatusProps {
	title: string;
	className: string | undefined;
}

export const CelerumStatus = ({ title, className }: CelerumStatusProps) => (
	<div className={cls(styles.container, className)}>{title}</div>
);
