export const TRUCKS_FEATURE_KEY = "trucks";

export const TRUCKS_PAGE_SIZE = 50;

export const NEW_TRUCK_STATE = {
	id: -1,
	name: "",
	registration: "",
	nextMOTDate: "",
	nextInspectionTime: "",
	notes: "",
	status: "",
	businessUnitName: "",
	documents: [],
	truckType: {
		id: -1,
		name: "",
		vehicleType: "",
	},
	constraints: [],
	subcontractor: undefined,
};
