import styles from "./not-found.module.scss";

export const NotFoundPage = () => {
	return (
		<div className={styles.container}>
			<h1>404</h1>
			<h2>PAGE NOT FOUND.</h2>
		</div>
	);
};
