import cls from "classnames";
import { useEffect, useState } from "react";
import {
	DEFAULT_TAG_LIMIT_3,
	DEFAULT_TAG_LIMIT_5,
	SCREEN_WIDTH_1920,
} from "../../../../../../../models/src/lib/constants/breakpoints.constants";
import { NOT_AVAILABLE } from "../../../../../../../models/src/lib/constants/messages.constants";
import { ModalSize } from "../../../../../../../models/src/lib/enums/modal.enums";
import type { IBase } from "../../../../../../../models/src/lib/interfaces/base.interface";
import { CelerumModal } from "../../../celerum-modal/celerum-modal.component";
import styles from "./associated-entities-wrapper.module.scss";

interface AssociatedEntitiesWrapperProps {
	values: IBase[];
	title: string;
	navigateToEntity: (id: number) => void;
}

interface ViewAllTagProps {
	onClick: () => void;
}

const ViewAllTag = (props: ViewAllTagProps) => {
	return (
		<div className={cls(styles.grid__item, styles.grid__item__all)} {...props}>
			view all
		</div>
	);
};

export const AssociatedEntitiesWrapper = ({
	values,
	title,
	navigateToEntity,
}: AssociatedEntitiesWrapperProps) => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const limit =
		windowWidth >= SCREEN_WIDTH_1920
			? DEFAULT_TAG_LIMIT_5
			: DEFAULT_TAG_LIMIT_3;

	const handleClick = (item: IBase) => {
		if (item) {
			navigateToEntity(item.id);
		}
	};

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleWindowResize);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);

	return (
		<>
			{values.length > 0 ? (
				<div className={styles.grid}>
					{values
						.filter((_x, i) => i < limit)
						.map((item) => (
							<div
								key={item.id}
								className={cls(styles.grid__item, styles.grid__item__value)}
								onClick={() => handleClick(item)}
								onKeyUp={() => handleClick(item)}
							>
								{item?.name ?? NOT_AVAILABLE}
							</div>
						))}
					{values.length > limit && (
						<ViewAllTag onClick={() => setShowModal(true)} />
					)}
				</div>
			) : (
				<p className={styles.grid__placeholder}>No records available!</p>
			)}
			<CelerumModal
				visible={showModal}
				title={title}
				width={ModalSize.Small}
				toggleDialog={() => setShowModal(false)}
			>
				<div className={styles.modal}>
					{values.map((item: IBase) => (
						<div
							key={item.id}
							className={styles.modal__item}
							onClick={() => handleClick(item)}
							onKeyUp={() => handleClick(item)}
						>
							{item?.name ?? NOT_AVAILABLE}
						</div>
					))}
				</div>
			</CelerumModal>
		</>
	);
};
