import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { type ComponentProps, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type InferType, number, object, string } from "yup";
import { DFlex } from "../display/DFlex";
import { GenericForm, IText } from "../forms/GenericForm";
import { type TypedGridColumnProps, jobApi, toasted } from "../helpers";
import { GenericPage } from "./GenericPage";

const LoadTypeSchema = object({
	id: number().label("ID"),
	name: string().required().label("Name"),
});
type LoadTypeFormObject = InferType<typeof LoadTypeSchema>;

type LoadTypeFormProps = {
	defaultValues?: Partial<LoadTypeFormObject>;
	onSubmit: (data: LoadTypeFormObject) => void;
};
const LoadTypeForm = ({ defaultValues, onSubmit }: LoadTypeFormProps) => {
	const form = useForm<LoadTypeFormObject>({
		resolver: yupResolver(LoadTypeSchema),
		defaultValues,
	});
	return (
		<GenericForm
			form={form}
			schema={LoadTypeSchema}
			onSubmit={async (x) => onSubmit(x)}
		>
			<DFlex>
				<div>
					<IText n="name" />
				</div>
			</DFlex>
		</GenericForm>
	);
};
const LoadTypeFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<LoadTypeFormProps, "onSubmit" | "defaultValues">) => (
	<LoadTypeForm
		defaultValues={defaultValues}
		onSubmit={async (data) => {
			const { id, ...rest } = data;
			const processData = async () => {
				if (id) await jobApi.loadType.loadTypeUpdate({ id, ...rest, order: 0 });
				else await jobApi.loadType.loadTypeCreate({ ...rest, order: 0 });
				onSubmit(data);
			};
			toasted(processData(), id ? "Updating Load Type" : "Creating Load Type");
		}}
	/>
);

type LoadType = LoadTypeFormObject & { id: number };
const defaultColumns: TypedGridColumnProps<LoadType>[] = [
	{ field: "name", title: "Name" },
];
const useFetchData = (): ComponentProps<
	typeof GenericPage<LoadType>
>["data"] => {
	const _loadTypes = useQuery({
		queryKey: ["jobApi.loadType.loadTypeList"],
		queryFn: () => jobApi.loadType.loadTypeList({}).then((x) => x.data.data),
		initialData: [],
	});
	const loadTypes = useMemo(
		() =>
			_loadTypes.data.map((x): LoadType => {
				return {
					id: x.id,
					name: x.name,
				};
			}),
		[_loadTypes.data],
	);
	return {
		data: loadTypes,
		retry: _loadTypes.refetch,
		loading: _loadTypes.isFetching,
	};
};
export const LoadTypesPage2 = () => {
	const data = useFetchData();
	const handleDelete = (id: number) =>
		toasted(
			jobApi.loadType.loadTypeDelete(id).then(data.retry),
			"Deleting Load Type",
		);
	const getForm = (
		id: number | undefined,
		onSubmit: (data: LoadTypeFormObject) => void,
	) => {
		let defaultValues: Partial<LoadTypeFormObject> = {};
		if (id) defaultValues = data.data.find((x) => x.id === id) ?? {};
		return (
			<LoadTypeFormWithDTO onSubmit={onSubmit} defaultValues={defaultValues} />
		);
	};
	return (
		<GenericPage
			pageTitle="Load Types"
			name="Load Type"
			data={data}
			onDelete={handleDelete}
			defaultColumns={defaultColumns}
			getForm={getForm}
		/>
	);
};
