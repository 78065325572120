import { CURRENCIES_FEATURE_KEY } from "../../../../common/models/src/lib/constants/currencies.constants";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	CurrenciesState,
	ICurrency,
} from "../../../../common/models/src/lib/interfaces/currency.interface";
import {
	fetchCurrencies,
	fetchCurrencyForCurrentBusinessUnit,
} from "./currencies-data-access";

import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";

const initialState: CurrenciesState = {
	data: [],
	loading: false,
	total: 0,
	aggregateResults: null,
	currentCurrency: null,
	errors: null,
};

export const fetchCurrenciesAction = createAsyncThunk(
	"currencies/fetchCurrencies",
	async () => {
		return fetchCurrencies();
	},
);

export const fetchCurrencyForCurrentBusinessUnitAction = createAsyncThunk(
	"currencies/fetchCurrencyForCurrentBusinessUnit",
	async () => {
		return fetchCurrencyForCurrentBusinessUnit();
	},
);

const currenciesSlice = createSlice({
	name: CURRENCIES_FEATURE_KEY,
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchCurrenciesAction.pending, (state: CurrenciesState) => {
				state.loading = true;
			})
			.addCase(
				fetchCurrencyForCurrentBusinessUnitAction.pending,
				(state: CurrenciesState) => {
					state.loading = true;
				},
			)
			/** Fulfilled */
			.addCase(
				fetchCurrenciesAction.fulfilled,
				(state: CurrenciesState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults } = action.payload;
					state.data = data as ICurrency[];
					state.total = total;
					state.aggregateResults = aggregateResults;
					state.loading = false;
				},
			)
			.addCase(
				fetchCurrencyForCurrentBusinessUnitAction.fulfilled,
				(state: CurrenciesState, action: PayloadAction<ICurrency>) => {
					state.currentCurrency = action.payload;
					state.loading = false;
				},
			)
			/** Rejected */
			.addCase(
				fetchCurrencyForCurrentBusinessUnitAction.rejected,
				(state: CurrenciesState) => {
					state.loading = false;
				},
			)
			.addCase(fetchCurrenciesAction.rejected, (state: CurrenciesState) => {
				state.loading = false;
			});
	},
});

/** Reducer */
export const currenciesReducer = currenciesSlice.reducer;
