import type { IBase } from "../../../../common/models/src/lib/interfaces/base.interface";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import { jobApi } from "../../../../common/utils/src/lib/axios.utils";
import { buildQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";

export const fetchConstraints = async (params: CelerumQueryParams) => {
	const query = buildQueryString(params);

	const response = await jobApi.get<CelerumResponse>(`/Constraint${query}`);
	return response.data;
};

export const createConstraint = async (constraint: IBase) => {
	const response = await jobApi.post("/Constraint", constraint);
	return response.data;
};

export const updateConstraint = async (constraint: IBase) => {
	const response = await jobApi.put("/Constraint", constraint);
	return response.data;
};

export const deleteConstraint = async (id: number) => {
	await jobApi.delete(`/Constraint/${id}`);
	return id;
};
