import { CelerumButton } from "../celerum-buttons/celerum-buttons.component";
import styles from "./celerum-grid-header.module.scss";

interface CelerumGridHeaderProps {
	title: string;
	numberOfItems: number;
	numberOfSelectedItems?: number;
	addButtonName?: string;
	handleOpenAddModal?: () => void;
	children?: React.ReactNode;
	addButtonDisabled?: boolean;
}

export const CelerumGridHeader = ({
	title,
	numberOfItems,
	numberOfSelectedItems,
	addButtonName,
	handleOpenAddModal,
	children,
	addButtonDisabled = false,
}: CelerumGridHeaderProps) => (
	<div className={styles.root}>
		<div className={styles.wrapper} style={{ width: "20%" }}>
			<h2 className={styles.title}>{title}</h2>
			<div className={styles.total}>
				<p>{numberOfItems}</p>
			</div>
			{numberOfSelectedItems != null && (
				<div className={styles.total}>
					<p>{numberOfSelectedItems}</p>
				</div>
			)}
		</div>
		<div
			className={styles.wrapper}
			style={{ width: "80%", justifyContent: "flex-end" }}
		>
			{children}
			{addButtonName && (
				<div style={{ marginLeft: "32px" }}>
					<CelerumButton
						disabled={addButtonDisabled}
						title={addButtonName}
						onClick={handleOpenAddModal}
					/>
				</div>
			)}
		</div>
	</div>
);
