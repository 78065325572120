import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	ILocation,
	ILocationRequestDto,
} from "../../../../common/models/src/lib/interfaces/location.interface";
import { jobApi } from "../../../../common/utils/src/lib/axios.utils";
import { buildQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";

export const fetchLocations = async (params: CelerumQueryParams = {}) => {
	const { page, pageSize } = params;
	const query = buildQueryString(params);

	const response = await jobApi.get<CelerumResponse>(`/Location${query}`);
	return {
		...response.data,
		page,
		pageSize,
	};
};

export const createLocation = async (location: ILocationRequestDto) => {
	const response = await jobApi.post<ILocation>("/Location", location);
	return response.data;
};

export const updateLocation = async (location: ILocationRequestDto) => {
	const response = await jobApi.put<ILocation>("/Location", location);
	return response.data;
};

export const deleteLocation = async (id: number) => {
	await jobApi.delete(`/Location/${id}`);
	return id;
};
