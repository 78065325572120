import { SupplierInvoiceType } from "../enums/invoice.enum";
import { JobStatus } from "../enums/job.enum";

export const INVOICES_FEATURE_KEY = "invoices";
export const INVOICES_PAGE_SIZE = 50;

export const NEW_INVOICE_STATE = {
	id: -1,
	name: "",
	assignedTo: undefined,
	customer: undefined,
	startDate: "",
	endDate: "",
	endLocation: "",
	invoiceNumber: "",
	grossSum: 0,
	jobType: {
		id: -1,
		name: "",
	},
	price: 0,
	status: JobStatus.READY_FOR_INVOICE,
	uniqueId: "",
	vatRate: "",
	uniqueIdList: [],
};

export const INVOICE_FREQUENCY: string[] = ["Daily", "Weekly", "Monthly"];

export const INVOICE_TYPE: string[] = ["Single Invoice", "Multiple Invoice"];

export const INVOICE_SEND_METHOD: string[] = ["Manual", "Email"];

export const RADIO_BUTTON: string[] = ["Yes", "No"];

export const INVOICE_STATUSES = [
	{
		id: JobStatus.READY_FOR_INVOICE,
		name: "Unchecked",
	},
	{
		id: JobStatus.INVOICE_GENERATED,
		name: "Invoice Generated",
	},
	{
		id: JobStatus.INVOICE_FAILED,
		name: "Invoice Failed",
	},
	{
		id: JobStatus.INVOICE_SENT,
		name: "Invoice Sent",
	},
	{
		id: JobStatus.CHECKED,
		name: "Checked",
	},
	{
		id: JobStatus.READY_FOR_REINVOICE,
		name: "Ready for re-invoice",
	},
	{
		id: JobStatus.CHECKED_AGAIN,
		name: "Checked again",
	},
	{
		id: JobStatus.REINVOICE_GENERATED,
		name: "Re-invoice generated",
	},
	{
		id: JobStatus.REINVOICE_SENT,
		name: "Re-invoice sent",
	},
	{
		id: JobStatus.SCHEDULED_FOR_INVOICE,
		name: "Scheduled for invoice",
	},
];

export const SUPPLIER_INVOICES = [
	{
		id: SupplierInvoiceType.NoInvoice,
		name: "No Invoice",
	},
	{
		id: SupplierInvoiceType.ToBeInvoiced,
		name: "To Be Invoiced",
	},
	{
		id: SupplierInvoiceType.Invoiced,
		name: "Invoiced",
	},
	{
		id: SupplierInvoiceType.Review,
		name: "Review",
	},
];
