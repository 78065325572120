import {
	Field,
	Form,
	FormElement,
	type FormRenderProps,
} from "@progress/kendo-react-form";
import cls from "classnames";
import type { ComponentProps, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import {
	CelerumButton,
	CelerumWhiteButton,
} from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumEmailInput } from "../../../../../../common/ui/src/lib/components/celerum-input/celerum-input.component";
import { forgotPasswordAction } from "../../../../../data-access/src/lib/authentication.slice";
import styles from "./forgot-password-form.module.scss";

interface ForgotPasswordFormProps {
	setIsEmailLinkSent: Dispatch<SetStateAction<boolean>>;
}

type FormProps = ComponentProps<typeof Form>;
export const ForgotPasswordForm = ({
	setIsEmailLinkSent,
}: ForgotPasswordFormProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { loading } = useAppSelector((state) => state.authentication);
	const { isMobile } = useAppSelector((state) => state.userInterface);

	const handleSubmit: FormProps["onSubmit"] = (dataItem) => {
		dispatch(forgotPasswordAction(dataItem.email));
		setIsEmailLinkSent(true);
	};

	return (
		<Form
			onSubmit={handleSubmit}
			render={(formRenderProps: FormRenderProps) => {
				return (
					<FormElement className={cls(isMobile ? styles.element : "")}>
						<div className={styles.formContainer}>
							<Field name="email" component={CelerumEmailInput} type="email" />
						</div>
						<div className={styles.buttonContainer}>
							<CelerumWhiteButton
								onClick={() => navigate("/login")}
								style={{ width: "50%" }}
								title={"Go back to Login"}
							/>
							<CelerumButton
								type="submit"
								style={{ width: "50%" }}
								disabled={!formRenderProps.allowSubmit}
								title={loading ? "Loading..." : "Email me a reset link"}
							/>
						</div>
					</FormElement>
				);
			}}
		/>
	);
};
