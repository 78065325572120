import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { type TableNameType, auditApi } from "../helpers";
import { toCell, useDialog } from "../helpersReact";

const mergeLists = (keys: string[], values: string[]) =>
	keys.map((key, index) => `${key}: ${values[index]}`).join("\n");

type AuditDialogBodyProps = {
	data: {
		id: string;
		date: string;
		user?: string | null;
		keys: string[];
		previousValue: string[];
		currentValues: string[];
	}[];
};
const AuditDialogBody = ({ data }: AuditDialogBodyProps) => (
	<table>
		<thead>
			<tr>
				<th>Date</th>
				<th>Username</th>
				<th>Diff</th>
			</tr>
		</thead>
		<tbody>
			{data?.map((x) => (
				<tr key={x.id}>
					{toCell(dayjs(x.date).format("YYYY-MM-DD HH:mm:ss"))}
					{toCell(x.user ?? "?")}
					<td style={{ minWidth: "600px" }}>
						<ReactDiffViewer
							oldValue={mergeLists(x.keys, x.previousValue)}
							newValue={mergeLists(x.keys, x.currentValues)}
							hideLineNumbers
							hideMarkers
							compareMethod={DiffMethod.WORDS}
						/>
					</td>
				</tr>
			))}
		</tbody>
	</table>
);

type AuditDialogBodyWithDTOProps = {
	type: TableNameType;
	id: string | number;
};
const AuditDialogBodyWithDTO = ({ type, id }: AuditDialogBodyWithDTOProps) => {
	const response = useQuery({
		queryKey: ["auditApi.audit.auditV1SearchHistoryList", type, id],
		queryFn: async () => {
			if (!id) {
				return [];
			}

			const history = await auditApi.audit.auditV1SearchHistoryList({
				Type: type,
				KeyValues: `${id}`,
			});

			if (history.data.length === 0) {
				const audit = await auditApi.audit.auditV1SearchList({
					Type: type,
					KeyValues: `${id}`,
				});

				return audit.data.map((x) => ({
					...x,
					date: x.auditDate,
					user: x.auditUser,
					keys: JSON.parse(x.affectedColumns ?? "[]"),
					previousValue: JSON.parse(x.oldValues ?? "{}"),
					currentValues: JSON.parse(x.newValues ?? "{}"),
				}));
			}

			return history.data
				.sort((one, two) => (new Date(one.date) > new Date(two.date) ? -1 : 1))
				.map((x) => ({
					...x,
					keys: x.records.map((record) => record.property),
					previousValue: x.records.map(
						(record) => record.previousValue?.toString() || "",
					),
					currentValues: x.records.map(
						(record) => record.currentValue?.toString() || "",
					),
				}));
		},
		initialData: [],
	});

	if (!response.isFetchedAfterMount) {
		return "Loading...";
	}

	return <AuditDialogBody data={response.data} />;
};

export const useAuditDialog = (_type: TableNameType) => {
	const [id, setId] = useState<string | number>("");
	const [type, setType] = useState<TableNameType>(_type);
	const [_toggleDialog, dialog] = useDialog(
		<AuditDialogBodyWithDTO id={id} type={type} />,
		"Audit History",
	);

	const toggleDialog = useCallback(
		(id: string | number, type?: TableNameType) => {
			setId(id);
			type && setType(type);
			_toggleDialog();
		},
		[_toggleDialog],
	);

	return [toggleDialog, dialog] as const;
};
