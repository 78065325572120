import { Icon, SvgIcon } from "@progress/kendo-react-common";
import {
	Input,
	InputClearValue,
	InputPrefix,
	type InputProps,
	InputSuffix,
	TextBox,
	type TextBoxHandle,
	type TextBoxProps,
} from "@progress/kendo-react-inputs";
import { Hint, Label, Error as LabelError } from "@progress/kendo-react-labels";
import { eyeIcon, searchIcon } from "@progress/kendo-svg-icons";
import cls from "classnames";
import { type MutableRefObject, useState } from "react";
import { handleSearchInputClear } from "../../../../../utils/src/lib/helpers/filter.helpers";
import styles from "./celerum-input.module.scss";

type CelerumEmailInputProps = InputProps & {
	visited: boolean;
	validationMessage: string;
};
export const CelerumEmailInput = ({
	validationMessage,
	visited,
	...others
}: CelerumEmailInputProps) => (
	<div className={styles.root}>
		<Label>Email</Label>
		<Input {...others} className={styles.input} />
		{visited && validationMessage && (
			<LabelError>{validationMessage}</LabelError>
		)}
	</div>
);

type CelerumSearchInputProps = TextBoxProps & {
	inputRef: MutableRefObject<TextBoxHandle>;
	setSearchValue: (value: string) => void;
};
export const CelerumSearchInput = ({
	inputRef,
	setSearchValue,
	...others
}: CelerumSearchInputProps) => (
	<TextBox
		ref={inputRef}
		{...others}
		prefix={() => (
			<InputPrefix className={styles.search__iconContainer}>
				<SvgIcon icon={searchIcon} />
			</InputPrefix>
		)}
		suffix={() => (
			<InputSuffix>
				<InputClearValue
					onClick={() => handleSearchInputClear(setSearchValue, inputRef)}
				>
					<Icon name="x" />
				</InputClearValue>
			</InputSuffix>
		)}
		className={styles.search}
		placeholder="Search"
	/>
);

type CelerumPasswordInputProps = InputProps & {
	visited: boolean;
	validationMessage: string;
	errorMessage?: string;
	hint?: string;
	label?: string;
	touched?: boolean;
	id?: string;
};
export const CelerumPasswordInput = ({
	touched,
	validationMessage,
	visited,
	hint,
	id,
	label,
	errorMessage,
	...others
}: CelerumPasswordInputProps) => {
	const [viewPassword, setViewPassword] = useState<boolean>(false);

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";

	return (
		<div className={styles.root}>
			<Label>{label}</Label>
			<div className={styles.passwordInputContainer}>
				<Input
					{...others}
					type={viewPassword ? "text" : "password"}
					maxLength={45}
					className={styles.input}
				/>
				<SvgIcon
					className={
						viewPassword
							? cls(styles.eyeIcon, styles.eyeIcon__enable)
							: cls(styles.eyeIcon, styles.eyeIcon__disabled)
					}
					icon={eyeIcon}
					onClick={(e) => {
						e.preventDefault();
						setViewPassword((prev: boolean) => !prev);
					}}
				/>
			</div>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{visited && validationMessage && (
				<LabelError>{validationMessage}</LabelError>
			)}
			{errorMessage && <LabelError>{errorMessage}</LabelError>}
		</div>
	);
};
