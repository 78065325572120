import { useQuery } from "@tanstack/react-query";
import { type ComponentProps, useMemo } from "react";
import type { LocationFormObject } from "../Location";
import { LocationFormWithDTO } from "../forms/LocationFormWithDTO";
import { type TypedGridColumnProps, jobApi, toasted } from "../helpers";
import { GenericPage } from "./GenericPage";

type Location = LocationFormObject & {
	id: number;
	customerString: string;
};
const defaultColumns: TypedGridColumnProps<Location>[] = [
	{ field: "name", title: "Name" },
	{ field: "customerString", title: "Customer" },
	{ field: "address", title: "Address" },
	{ field: "latitude", title: "Latitude", hidden: true },
	{ field: "longitude", title: "Longitude", hidden: true },
	{ field: "notes", title: "Notes" },
];
const useFetchData = (): ComponentProps<
	typeof GenericPage<Location>
>["data"] => {
	const _locations = useQuery({
		queryKey: ["jobApi.location.locationList"],
		queryFn: () => jobApi.location.locationList({}).then((x) => x.data.data),
		initialData: [],
	});
	const locations = useMemo(
		() =>
			_locations.data.map(
				(x): Location => ({
					id: x.id,
					customerId: x.customer?.id,
					customerString: x.customer?.name ?? "",
					name: x.name,
					address: x.address,
					latitude: x.latitude ?? 0,
					longitude: x.longitude ?? 0,
					notes: x.notes ?? undefined,
				}),
			),
		[_locations.data],
	);
	return {
		data: locations,
		retry: _locations.refetch,
		loading: _locations.isFetching,
	};
};
export const LocationPage2 = () => {
	const data = useFetchData();
	const handleDelete = (id: number) =>
		toasted(
			jobApi.location.locationDelete(id).then(data.retry),
			"Deleting Location",
		);
	const getForm = (
		id: number | undefined,
		onSubmit: (data: LocationFormObject) => void,
	) => {
		let defaultValues: Partial<LocationFormObject> = { name: "" };
		if (id) defaultValues = data.data.find((x) => x.id === id) ?? {};
		return (
			<LocationFormWithDTO onSubmit={onSubmit} defaultValues={defaultValues} />
		);
	};
	return (
		<GenericPage
			pageTitle="Locations"
			name="Location"
			data={data}
			onDelete={handleDelete}
			defaultColumns={defaultColumns}
			getForm={getForm}
		/>
	);
};
