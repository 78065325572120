import { SvgIcon } from "@progress/kendo-react-common";
import type { SVGIcon } from "@progress/kendo-svg-icons";
import styles from "../../leg-accordion-content.module.scss";

export const TitleSection = ({
	icon,
	title,
	value,
}: { icon: SVGIcon; title: string; value: string }) => (
	<div className={styles.information}>
		<div className={styles.information__title}>
			<SvgIcon icon={icon} width={14} height={14} />
			<span className={styles.information__title__label}>{title}</span>
		</div>
		<span className={styles.information__value}>{value}</span>
	</div>
);
