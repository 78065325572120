import { useEffect, useRef, useState } from "react";

export const useScrollbarDetection = () => {
	const containerRef = useRef<HTMLDivElement>(null);
	const [hasScrollBar, setHasScrollBar] = useState<boolean>(false);

	useEffect(() => {
		const container = containerRef.current;

		if (container) {
			const handleScrollbarAppearance = () => {
				const hasScroll = container.scrollHeight > container.clientHeight;
				setHasScrollBar(hasScroll);
			};

			handleScrollbarAppearance();

			const observer = new ResizeObserver(handleScrollbarAppearance);
			observer.observe(container);

			return () => {
				observer.unobserve(container);
			};
		}

		return () => {};
	}, []);

	return { containerRef, hasScrollBar };
};
