import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTitle } from "react-use";
import {
	resetAuthenticationNotificationAction,
	selectBusinessUnitAction,
} from "../../../libs/authentication/data-access/src/lib/authentication.slice";
import { navigateAfterBusinessUnitSelection } from "../../../libs/authentication/feature/src/lib/helpers/authentication.helpers";
import { Login } from "../../../libs/authentication/feature/src/lib/login/login.component";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../libs/common/stores/src/lib/utils";
import styles from "./login.module.scss";

export const LoginPage = () => {
	useTitle("Login");
	const dispatch = useAppDispatch();

	const { token, currentBusinessUnit, businessUnits } = useAppSelector(
		(state) => state.authentication,
	);
	const navigate = useNavigate();

	useEffect(() => {
		if (token) {
			if (businessUnits?.length === 1 && !currentBusinessUnit) {
				dispatch(
					selectBusinessUnitAction({
						id: businessUnits[0]?.id,
						name: businessUnits[0]?.name,
					}),
				);
				navigateAfterBusinessUnitSelection(navigate);
			} else {
				currentBusinessUnit
					? navigateAfterBusinessUnitSelection(navigate)
					: navigate("/business-unit-selection");
			}
		}
	}, [navigate, token, currentBusinessUnit, businessUnits, dispatch]);

	useEffect(() => {
		dispatch(resetAuthenticationNotificationAction());
	}, [dispatch]);

	return (
		<div className={styles.root}>
			<div className={styles.leftSide} />
			<div className={styles.rightSide}>
				<Login />
			</div>
		</div>
	);
};
