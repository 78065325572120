import { Reveal } from "@progress/kendo-react-animation";
import {
	ExpansionPanel,
	type ExpansionPanelActionEvent,
	ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import cls from "classnames";
import { useState } from "react";
import type { ParentType } from "../../../../../../common/models/src/lib/enums/parent-type.enum";
import type { IGoodsResponseDto } from "../../../../../../common/models/src/lib/interfaces/goods.interface";
import { useAppSelector } from "../../../../../../common/stores/src/lib/utils";
import { useScrollbarDetection } from "../../../../../../common/utils/src/lib/hooks/use-scrollbar-detection.hook";
import { GoodsAccordionContent } from "../goods-accordion-content/goods-accordion-content.component";
import { GoodsAccordionHeader } from "../goods-accordion-header/goods-accordion-header.component";
import styles from "./goods-card-container.module.scss";

interface GoodsCardContainerProps {
	parentType: ParentType;
	showEditButtonInHeader?: boolean;
	showDeleteButtonInHeader?: boolean;
	setSelectedGoods: (goods: IGoodsResponseDto) => void;
	handleDeleteGoods: (id: number, name: string) => void;
}

export const GoodsCardContainer = ({
	showEditButtonInHeader = true,
	showDeleteButtonInHeader = true,
	setSelectedGoods,
	handleDeleteGoods,
	parentType,
}: GoodsCardContainerProps) => {
	const { containerRef, hasScrollBar } = useScrollbarDetection();

	const { data } = useAppSelector((state) => state.goods);

	const [expanded, setExpanded] = useState<number | undefined>();

	return (
		<div
			className={cls(
				styles.container,
				hasScrollBar ? styles.scrollPadding : "",
			)}
			ref={containerRef}
		>
			{data.map((goodsItem: IGoodsResponseDto, index: number) => (
				<div key={`${goodsItem.id}-${index}`} className={styles.accordion}>
					<ExpansionPanel
						title={
							<GoodsAccordionHeader
								parentType={parentType}
								goods={goodsItem}
								setSelectedGoods={setSelectedGoods}
								handleDeleteGoods={handleDeleteGoods}
								showEditButton={showEditButtonInHeader}
								showDeleteButton={showDeleteButtonInHeader}
							/>
						}
						expanded={expanded === goodsItem.id}
						onAction={(event: ExpansionPanelActionEvent) => {
							setExpanded(event.expanded ? undefined : goodsItem.id);
						}}
						tabIndex={index}
					>
						<Reveal>
							{expanded === goodsItem.id && (
								<ExpansionPanelContent>
									<div onKeyDown={(e) => e.stopPropagation()}>
										<GoodsAccordionContent goods={goodsItem} />
									</div>
								</ExpansionPanelContent>
							)}
						</Reveal>
					</ExpansionPanel>
				</div>
			))}
		</div>
	);
};
