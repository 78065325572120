export const USERS_PAGE_SIZE = 50;
export const USERS_FEATURE_KEY = "users";
export const OPERATOR_ROLE = "Operator";
export const ADMIN_ROLE = "Admin";
export const ACCOUNTANT_ROLE = "Accountant";
export const SUPER_ADMIN_ROLE = "SuperAdmin";

export const USER_STATUSES = [
	{ id: 1, name: "Active" },
	{ id: 2, name: "Inactive" },
];

export const USER_STATUS_MAPPINGS: {
	[key: number]: { title: string; className: string };
} = {
	1: {
		title: "Active",
		className: "green",
	},
	2: {
		title: "Inactive",
		className: "gray",
	},
};

export const NEW_USER_STATE = {
	id: -1,
	firstName: "",
	lastName: "",
	fullName: "",
	roles: [],
	email: "",
	username: "",
	driver: undefined,
	driverId: -1,
	status: 0,
};
