export enum LoadStatus {
	New = 1,
	Building = 2,
	Accepted = 3,
	Underway = 4,
	WorkFinished = 5,
	Paused = 6,
	Cancelled = 7,
	Archived = 8,
	Planned = 9,
}
