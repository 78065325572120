import type { DateTimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import type {
	ComboBoxChangeEvent,
	MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import type { InputChangeEvent } from "@progress/kendo-react-inputs";
import type { Dispatch, SetStateAction } from "react";

export const useInputChange = () => {
	const handleInputChange = (
		event:
			| DateTimePickerChangeEvent
			| InputChangeEvent
			| MultiSelectChangeEvent
			| ComboBoxChangeEvent,
		field: string,
		// biome-ignore lint/suspicious/noExplicitAny: no thanks
		setFormData: Dispatch<SetStateAction<any>>,
		// biome-ignore lint/suspicious/noExplicitAny: no thanks
		fieldValue?: any,
	) => {
		const { value } = event.target;
		setFormData((prevFormData: object) => ({
			...prevFormData,
			[field]: fieldValue ?? value,
		}));
	};

	return handleInputChange;
};
