import type { CelerumQueryParams } from "../../../../models/src/lib/interfaces/celerum-query-params.interface";
import type {
	IDateFilter,
	IFilterItem,
} from "../../../../models/src/lib/interfaces/filter.interface";

export const buildQueryString = (params: CelerumQueryParams): string => {
	const query = Object.entries(params)
		.filter(([, value]) => Boolean(value))
		.map(([key, value]) =>
			key === "sort" || key === "filters" ? value : `${key}=${value}`,
		)
		.join("&");
	return query ? `?${query}` : "";
};

export const buildFilterQueryString = (
	searchValue: string | undefined,
	columnsToSearch: string[],
	dateList?: IDateFilter[],
	typeLists?: Array<IFilterItem[]>,
	multipleFilterItemList?: IFilterItem[],
) => {
	const result: string[] = [];

	const addFilter = (filter: string) => {
		result.push(filter);
	};

	const addSearch = () => {
		if (!searchValue) return;
		const searchFilters = columnsToSearch.map((column, index) => {
			const operator = index === columnsToSearch.length - 1 ? ")" : "~or~";
			return `${column}~contains~'${encodeURIComponent(
				searchValue,
			)}'${operator}`;
		});
		addFilter(`(${searchFilters.join("")}`);
	};

	const addType = () => {
		typeLists?.forEach((typeList, index) => {
			if (typeList?.length) {
				const typeFilters = typeList.map((currentType) => {
					const typeIds = currentType.value.map(
						(id) =>
							`${currentType.id}~${currentType.neq ? "n" : ""}eq~%27${id}%27`,
					);
					return `(${typeIds.join("~or~")})`;
				});
				addFilter(typeFilters.join(""));
				if (index !== typeLists.length - 1 && typeLists[index + 1]?.length) {
					addFilter("~and~");
				}
			}
		});
	};

	const addMulitpleFilterItem = (multipleFilterItem: IFilterItem) => {
		for (const currentId of multipleFilterItem.value) {
			addFilter(`${multipleFilterItem.id}=${currentId}`);
			addFilter("&");
		}
	};

	const addDate = () => {
		if (dateList?.length) {
			const validDateFilters = dateList
				.filter(
					(currentDate) => currentDate.value.start && currentDate.value.end,
				)
				.map((currentDate, index) => {
					const { start, end } = currentDate.value;
					const operator = index === dateList.length - 1 ? ")" : "~and~";
					return `${currentDate.id}~gte~%27${start}%27~and~${currentDate.id}~lte~%27${end}%27${operator}`;
				});

			if (validDateFilters.length > 0) {
				addFilter(`(${validDateFilters.join("")}`);
			}
		}
	};

	if (multipleFilterItemList?.length) {
		multipleFilterItemList.forEach(addMulitpleFilterItem);
	}

	if (searchValue || dateList?.length || typeLists?.length) {
		addFilter("filter=(");
	}

	addSearch();

	if (searchValue && dateList?.length) {
		addFilter("~and~");
	}

	addDate();

	if ((searchValue || dateList?.length) && typeLists?.length) {
		addFilter("~and~");
	}

	addType();

	if (searchValue || dateList?.length || typeLists?.length) {
		addFilter(")");
	}

	return result.join("");
};
