import { CelerumActions } from "../../../../../common/models/src/lib/enums/actions.enum";
import { useAppDispatchWithNotifications } from "../../../../../common/stores/src/lib/utils";
import {
	cancelLoadAction,
	deleteLoadAction,
	generateManifestAction,
	generatePrintAllDocumentsAction,
	pauseLoadAction,
	resumeLoadAction,
} from "../../../../data-access/src/lib/loads.slice";

export const useLoadActionSelected = () => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	return (
		action: CelerumActions | null,
		id: number | null,
		uniqueId?: string,
	) => {
		if (!id || !action) {
			return;
		}

		switch (action) {
			case +CelerumActions.Cancel: {
				dispatchWithNotifications({
					action: cancelLoadAction,
					payload: id,
					successMessage: `Load with ID ${
						uniqueId ?? id
					} cancelled successfully.`,
					errorMessage: `Could not cancel load with ID ${uniqueId ?? id}.`,
				});
				break;
			}
			case +CelerumActions.Pause: {
				dispatchWithNotifications({
					action: pauseLoadAction,
					payload: id,
					successMessage: `Load with ID ${uniqueId ?? id} paused successfully.`,
					errorMessage: `Could not pause load with ID ${uniqueId ?? id}.`,
				});
				break;
			}
			case +CelerumActions.Delete: {
				dispatchWithNotifications({
					action: deleteLoadAction,
					payload: id,
					successMessage: `Load with ID ${
						uniqueId ?? id
					} deleted successfully.`,
					errorMessage: `Could not delete load with ID ${uniqueId ?? id}.`,
				});
				break;
			}
			case +CelerumActions.Resume: {
				dispatchWithNotifications({
					action: resumeLoadAction,
					payload: id,
					successMessage: `Load with ID ${
						uniqueId ?? id
					} resumed successfully.`,
					errorMessage: `Could not resume load with ID ${uniqueId ?? id}.`,
				});
				break;
			}
			case +CelerumActions["Generate Manifest"]: {
				dispatchWithNotifications({
					action: generateManifestAction,
					payload: id,
					errorMessage: `Could not generate manifest for load with ID ${
						uniqueId ?? id
					}.`,
				});
				break;
			}
			case +CelerumActions["Print All Documents"]: {
				dispatchWithNotifications({
					action: generatePrintAllDocumentsAction,
					payload: id,
					errorMessage: `Could not generate all documents for load with ID ${
						uniqueId ?? id
					}.`,
				});
				break;
			}
			case +CelerumActions["Clear Customs Documents"]: {
				break;
			}
		}
	};
};
