import { useEffect, useState } from "react";
import { LONDON_LOCATION } from "../../../../../common/models/src/lib/constants/location.constants";
import type { ILocationRequestDto } from "../../../../../common/models/src/lib/interfaces/location.interface";

export const useCurrentLocation = (formState: ILocationRequestDto) => {
	const [currentLocation, setCurrentLocation] = useState<
		google.maps.LatLngLiteral | undefined
	>(undefined);

	useEffect(() => {
		const updateLocation = () => {
			if (formState.latitude && formState.longitude) {
				setCurrentLocation({
					lat: formState.latitude,
					lng: formState.longitude,
				});
			} else if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						setCurrentLocation({
							lat: position.coords.latitude,
							lng: position.coords.longitude,
						});
					},
					() => {
						/** Handle case when user denies geolocation permission. */
						setCurrentLocation(LONDON_LOCATION);
					},
				);
			} else {
				setCurrentLocation(LONDON_LOCATION);
			}
		};

		updateLocation();
	}, [formState]);

	return currentLocation;
};
