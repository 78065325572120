import { LoadStatus } from "../enums/load.enum";

export const LOADS_FEATURE_KEY = "loads";
export const LOADS_PAGE_SIZE = 50;

export const NEW_LOAD_STATE = {
	id: -1,
	status: LoadStatus.New,
	creationDate: "",
	startDate: "",
	endDate: "",
	startLocation: null,
	endLocation: null,
	truck: null,
	truckType: null,
	trailer: null,
	trailerType: null,
	subcontractor: null,
	currency: null,
	uniqueId: "",
	assignedTo: "",
	cost: 0,
	profit: 0,
	numberOfGoods: 0,
	weight: 0,
	createdBy: "",
	documents: [],
};

export const LOAD_STATUSES = [
	{
		id: LoadStatus.New,
		name: "New",
	},
	{
		id: LoadStatus.Building,
		name: "Building",
	},
	{
		id: LoadStatus.Accepted,
		name: "Accepted",
	},
	{
		id: LoadStatus.Underway,
		name: "Underway",
	},
	{
		id: LoadStatus.WorkFinished,
		name: "Work Finished",
	},
	{
		id: LoadStatus.Paused,
		name: "Paused",
	},
	{
		id: LoadStatus.Cancelled,
		name: "Cancelled",
	},
	{
		id: LoadStatus.Archived,
		name: "Archived",
	},
	{
		id: LoadStatus.Planned,
		name: "Planned",
	},
];

export const LOAD_STATUS_MAPPINGS: {
	[key: number]: { title: string; className: string };
} = {
	1: {
		title: "New",
		className: "gray",
	},
	2: {
		title: "Building",
		className: "yellow",
	},
	3: {
		title: "Accepted",
		className: "blue",
	},
	4: {
		title: "Underway",
		className: "violet",
	},
	5: {
		title: "Work Finished",
		className: "orange",
	},
	6: {
		title: "Paused",
		className: "pink",
	},
	7: {
		title: "Cancelled",
		className: "green",
	},
	8: {
		title: "Archived",
		className: "gray",
	},
	9: {
		title: "Planned",
		className: "darkGreen",
	},
};
