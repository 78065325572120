export enum ErrorCode {
	DEFAULT = 0,
	UNAUTHORIZED = 5001,
	INVALID_CREDENTIALS = 5002,
	BAD_REQUEST = 5003,
	FORBIDDEN = 5004,
	INVALID_TOKEN = 5005,
	MESSAGE_NOT_SENT = 5006,
	DUPLICATE = 5007,
	INVALID_MODEL = 5008,
	NOT_CREATED = 5009,
	INVALID_FILE = 5010,
	INVALID_ACTIVATION_ACCOUNT_TOKEN = 6002,
}
