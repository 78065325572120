import { useRef } from "react";
import type { IFilterItem } from "../../../../../../models/src/lib/interfaces/filter.interface";
import { CelerumDropdownCategory } from "../../celerum-dropdown-category/celerum-dropdown-category.components";
import { CelerumPopupCheckboxSelection } from "../../celerum-popup/components/celerum-popup-checkbox-selection/celerum-popup-checkbox-selection.component";
import type { DropDownFilterItem } from "../interfaces/filters.interface";

interface CelerumDropDownWithCheckboxPopupProps {
	onClick: () => void;
	onClose: () => void;
	filter: DropDownFilterItem;
	filtered: boolean;
	filtersCount: number;
	show: boolean | undefined;
	handleSubmit: (ids: (number | string)[]) => void;
	initialValues: IFilterItem[];
}

export const CelerumDropDownWithCheckboxPopup = ({
	onClick,
	filtered,
	filter,
	show,
	handleSubmit,
	initialValues,
	onClose,
	filtersCount,
}: CelerumDropDownWithCheckboxPopupProps) => {
	const anchor = useRef<HTMLDivElement | null>(null);

	return (
		<>
			<CelerumDropdownCategory
				onClick={onClick}
				name={filter.name}
				anchor={anchor}
				filtered={filtered}
				show={show}
				filtersCount={filtersCount}
			/>
			<CelerumPopupCheckboxSelection
				show={show}
				initialValues={initialValues}
				anchor={anchor}
				values={(filter as DropDownFilterItem).values}
				filterTitle={filter.name}
				handleSubmit={handleSubmit}
				onClose={onClose}
			/>
		</>
	);
};
