import { Icon } from "@progress/kendo-react-common";
import { TypedDropDownButton } from "./TypedDropDownButton";
import { DFlex } from "./display/DFlex";

type GenericStatusFilterProps = {
	values: number[];
	possibleValues: { id: number; name: string }[];
	onChange: (selectedValues: number[]) => void;
	groups: { name: string; values: number[] }[];
};
export const GenericStatusFilter = ({
	values,
	possibleValues,
	onChange,
	groups,
}: GenericStatusFilterProps) => (
	<DFlex gap="6px">
		<TypedDropDownButton
			size="small"
			text={`Status: ${values.length ? values.length : "All"}`}
			items={possibleValues}
			itemRender={(i) => (
				<>
					<Icon name={values.includes(i.item.id) ? "check" : "x"} />
					<span>{i.item.name}</span>
				</>
			)}
			onItemClick={(e) =>
				values.includes(e.item.id)
					? onChange(values.filter((v) => v !== e.item.id))
					: onChange([...values, e.item.id])
			}
			popupSettings={{ animate: false }}
		/>
		<TypedDropDownButton
			icon="circle"
			size="small"
			title="Quick Select Status"
			items={groups}
			onItemClick={(e) => onChange(e.item.values)}
			itemRender={(i) => <span>{i.item.name}</span>}
			popupSettings={{ animate: false }}
		/>
	</DFlex>
);
