import { SvgIcon } from "@progress/kendo-react-common";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Input, type InputChangeEvent } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { plusIcon } from "@progress/kendo-svg-icons";
import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import { recycleBinIcon } from "../../../../../models/src/lib/constants/icon.constants";
import styles from "./celerum-add-items-in-input.module.scss";

interface CelerumAddItemsInInputProps {
	id: string;
	name: string;
	label: string;
	buttonName: string;
	result: string[];
	numberOfItemsLimit?: number;
	setResult: Dispatch<SetStateAction<string[]>>;
	setIsFormModified: Dispatch<SetStateAction<boolean>>;
	disabled?: boolean;
}

export const CelerumAddItemsInInput = ({
	label,
	id,
	name,
	buttonName,
	result,
	setResult,
	setIsFormModified,
	disabled = false,
	numberOfItemsLimit = 5,
}: CelerumAddItemsInInputProps) => {
	const [itemsToDisplay, setItemsToDisplay] = useState<string[]>(
		result.slice(1),
	);
	const [currentValue, setCurrentValue] = useState<string>(result[0] || "");

	const addItemToDisplay = (item: string) => {
		setItemsToDisplay([item, ...itemsToDisplay]);
		setCurrentValue("");
		setIsFormModified(true);
	};

	const removeItemFromDisplay = (item: string) => {
		setItemsToDisplay(
			itemsToDisplay.filter((currentItem: string) => item !== currentItem),
		);
		setIsFormModified(true);
	};

	useEffect(() => {
		if (currentValue) {
			setResult([currentValue.toString(), ...itemsToDisplay]);
		} else {
			setResult([...itemsToDisplay]);
		}
	}, [currentValue, itemsToDisplay, setResult]);

	return (
		<FieldWrapper className={styles.inputContainer}>
			<Label
				style={{
					color: disabled ? "#9D9DAB" : "#000000",
				}}
				className={styles.negativeMargin}
			>
				{label}
			</Label>
			<Input
				className={styles.input}
				id={id}
				name={name}
				value={currentValue}
				type="text"
				disabled={disabled}
				onChange={(e: InputChangeEvent) => {
					setCurrentValue(e.target.value as string);
					setIsFormModified(true);
				}}
			/>
			{!disabled && itemsToDisplay.length < numberOfItemsLimit - 1 && (
				<div
					className={styles.addButton}
					onClick={() => currentValue && addItemToDisplay(currentValue)}
					onKeyUp={() => currentValue && addItemToDisplay(currentValue)}
				>
					<SvgIcon icon={plusIcon} className={styles.plusIcon} />
					<p className={styles.addButtonName}>{buttonName}</p>
				</div>
			)}
			{!!itemsToDisplay.length &&
				itemsToDisplay.map((item: string) => {
					return (
						<div className={styles.itemContainer} key={item}>
							<p className={styles.itemName}>{item}</p>
							{!disabled && (
								<SvgIcon
									className={styles.deleteIcon}
									width={12}
									icon={recycleBinIcon}
									onClick={() => removeItemFromDisplay(item)}
								/>
							)}
						</div>
					);
				})}
		</FieldWrapper>
	);
};
