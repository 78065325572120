import { Loader } from "@progress/kendo-react-indicators";
import styles from "./celerum-loader.module.scss";

interface CelerumLoaderProps {
	visible: boolean;
	size?: "large" | "medium" | "small";
}

export const CelerumLoader = ({
	visible,
	size = "large",
}: CelerumLoaderProps) =>
	visible ? (
		<Loader className={styles.loader} size={size} type="infinite-spinner" />
	) : null;
