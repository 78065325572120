import { SvgIcon } from "@progress/kendo-react-common";
import { DrawerItem } from "@progress/kendo-react-layout";
import { houseIcon } from "../../../../../../../models/src/lib/constants/icon.constants";
import type { CelerumDrawerItemProps } from "../../../../../../../models/src/lib/interfaces/celerum-drawer-item-props.interface";
import { CelerumUserProfile } from "../../../celerum-user-profile/celerum-user-profile.component";
import styles from "./profile-drawer-item.module.scss";

export const ProfileDrawerItem = ({
	navbarExpanded,
	handleClickOnBusinessUnit,
	userProfile,
}: CelerumDrawerItemProps) => {
	const { firstName, lastName, businessUnitName } = userProfile;

	return !navbarExpanded ? (
		<DrawerItem onClick={handleClickOnBusinessUnit} className={styles.profile}>
			<CelerumUserProfile firstName={firstName} lastName={lastName} />
		</DrawerItem>
	) : (
		<DrawerItem onClick={handleClickOnBusinessUnit}>
			<div className={styles.profileExtendedContainer}>
				<CelerumUserProfile firstName={firstName} lastName={lastName} />
				<div className={styles.nameContainer}>
					<p className={styles.name}>
						{firstName} {lastName}
					</p>
					<div className={styles.businessUnit}>
						<SvgIcon icon={houseIcon} />{" "}
						<div className={styles.businessUnitName}>{businessUnitName}</div>
					</div>
				</div>
			</div>
		</DrawerItem>
	);
};
