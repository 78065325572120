export enum InvoiceTypeMethod {
	Invalid = 0,
	"Single Invoice" = 1,
	"Multiple Invoice" = 2,
}

export enum SupplierInvoiceType {
	NoInvoice = 0,
	ToBeInvoiced = 1,
	Invoiced = 2,
	Review = 3,
}
