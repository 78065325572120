import { useState } from "react";
import {
	GoodFormWithDTO,
	type GoodFormWithDTOProps,
} from "../forms/GoodFormWithDTO";
import { useDialog } from "../helpersReact";

export const useGoodFormDialog = () => {
	const [props, setProps] = useState<GoodFormWithDTOProps>();
	const [_toggleDialog, dialog] = useDialog(
		props && (
			<GoodFormWithDTO
				{...props}
				onSubmit={(x) => {
					props.onSubmit?.(x);
					_toggleDialog(false);
				}}
			/>
		),
		"Good Form",
	);

	const toggleDialog = (props?: GoodFormWithDTOProps) => {
		setProps(props);
		_toggleDialog(props);
	};

	return [toggleDialog, dialog] as const;
};
