import {
	Field,
	Form,
	FormElement,
	type FormProps,
} from "@progress/kendo-react-form";
import type {
	ILoadTypeRequestDto,
	ILoadTypeResponseDto,
} from "../../../../../../common/models/src/lib/interfaces/load-type.interface";
import {
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumSubmitButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumFormInput } from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { requiredValidator } from "../../../../../../common/utils/src/lib/validators/validators";
import {
	createLoadTypeAction,
	updateLoadTypeAction,
} from "../../../../../data-access/src/lib/load-types.slice";

interface LoadTypeFormProps {
	formState: ILoadTypeResponseDto | undefined;
	onClose: () => void;
}

export const LoadTypeForm = ({ formState, onClose }: LoadTypeFormProps) => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const { loading } = useAppSelector((state) => state.loadTypes);

	const handleSubmit: FormProps["onSubmit"] = async (dataItem) => {
		if (formState) {
			const actionResult = await dispatchWithNotifications({
				action: updateLoadTypeAction,
				payload: dataItem as ILoadTypeRequestDto,
				successMessage: `Load type named ${formState.name} updated successfully.`,
				errorMessage: `Could not update load type named ${formState.name}.`,
			});
			updateLoadTypeAction.fulfilled.match(actionResult) && onClose();
		} else {
			const actionResult = await dispatchWithNotifications({
				action: createLoadTypeAction,
				payload: dataItem as ILoadTypeRequestDto,
				successMessage: "Load type created successfully.",
				errorMessage: "Load type creation failed.",
			});
			createLoadTypeAction.fulfilled.match(actionResult) && onClose();
		}
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formState}
			render={(formRenderProps) => (
				<FormElement>
					<fieldset className="k-form-fieldset">
						<Field
							id="name"
							name="name"
							label="Name"
							component={CelerumFormInput}
							maxLength={100}
							type="text"
							validator={requiredValidator}
							required
							focused="true"
						/>
					</fieldset>
					<CelerumSubmitButton
						type="submit"
						disabled={!formRenderProps.allowSubmit || loading}
						title="Submit"
					/>
				</FormElement>
			)}
		/>
	);
};
