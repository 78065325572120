import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type { ICurrency } from "../../../../common/models/src/lib/interfaces/currency.interface";
import { jobApi } from "../../../../common/utils/src/lib/axios.utils";

export const fetchCurrencies = async () => {
	const response = await jobApi.get<CelerumResponse>("/Currency");
	return response.data;
};

export const fetchCurrencyForCurrentBusinessUnit = async () => {
	const response = await jobApi.get<ICurrency>("/Currency/businessUnit");
	return response.data;
};
