import { isAsyncThunkAction } from "@reduxjs/toolkit";
import { isEqual, memoize } from "lodash";
import {
	type TypedUseSelectorHook,
	useDispatch,
	useSelector,
} from "react-redux";
import {
	triggerErrorNotificationAction,
	triggerSuccessNotificationAction,
} from "../../../../user-interface/data-access/src/lib/user-interface.slice";
import type { ClientAppDispatch, ClientState } from "./client";

/**
 * Update these 2 types with new intersections of state and dispatch types
 * when new stores are added to the workspace.
 */
type AnyState = ClientState;
export type AppDispatch = ClientAppDispatch;
export const useAppDispatch = memoize(() => useDispatch<AppDispatch>());
export const useAppSelector: TypedUseSelectorHook<AnyState> = memoize(
	(selector) => useSelector<AnyState>(selector, (a, b) => isEqual(a, b)),
);

export const useAppDispatchWithNotifications = memoize(() => {
	const dispatch = useAppDispatch();

	const dispatchWithNotifications = async ({
		action,
		payload,
		successMessage,
		errorMessage,
	}: {
		// biome-ignore lint/suspicious/noExplicitAny: can't be bothered to type this
		action: any;
		// biome-ignore lint/suspicious/noExplicitAny: can't be bothered to type this
		payload?: any;
		successMessage?: string;
		errorMessage?: string;
	}) => {
		if (isAsyncThunkAction(action)) {
			const actionResult = await dispatch(action(payload));

			if (action.fulfilled.match(actionResult)) {
				if (successMessage) {
					dispatch(triggerSuccessNotificationAction(successMessage));
				}
			}

			if (action.rejected.match(actionResult)) {
				if (actionResult?.error?.message) {
					dispatch(triggerErrorNotificationAction(actionResult.error.message));
				} else {
					errorMessage &&
						dispatch(triggerErrorNotificationAction(errorMessage));
				}
			}

			return actionResult;
		}
		try {
			dispatch(action(payload));
			if (successMessage) {
				dispatch(triggerSuccessNotificationAction(successMessage));
			}
		} catch (e) {
			console.error(e);
			errorMessage && dispatch(triggerErrorNotificationAction(errorMessage));
		}
	};

	return dispatchWithNotifications;
});
