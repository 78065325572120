import type { SelectionRange } from "@progress/kendo-react-dateinputs";
import { NOT_AVAILABLE } from "../../../../models/src/lib/constants/messages.constants";
import type { IDateFilter } from "../../../../models/src/lib/interfaces/filter.interface";

export const formatDate = (date: Date) => {
	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const year = date.getFullYear();
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");

	const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

	return formattedDate;
};

export const toISOStringWithoutTimezoneOffset = (date: Date): string => {
	return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
		.toISOString()
		.slice(0, -1);
};

export const constructDerivedRange = (dateFilter: IDateFilter | undefined) => {
	const value = dateFilter?.value;
	if (!value) return null;
	return value.end && value.start
		? {
				start: new Date(value.start),
				end: new Date(value.end),
			}
		: null;
};

export const transformSelectionRange = (range: SelectionRange) => {
	let startDate: string | undefined;
	let endDate: string | undefined;

	const { start, end } = range;

	if (start) {
		const startWithoutOffset = toISOStringWithoutTimezoneOffset(start);
		startDate = startWithoutOffset;
	} else {
		startDate = undefined;
	}

	if (end) {
		const endWithoutOffset = toISOStringWithoutTimezoneOffset(end);
		endDate = endWithoutOffset;
	} else if (start && !end) {
		const startWithoutOffset = toISOStringWithoutTimezoneOffset(start);
		const adjustedEnd = new Date(startWithoutOffset);
		adjustedEnd.setHours(23, 59, 59, 999);
		endDate = toISOStringWithoutTimezoneOffset(adjustedEnd);
	} else {
		endDate = undefined;
	}

	return { startDate, endDate };
};

export const generateCreatedByText = (
	createdBy: string,
	creationTime?: string,
) => {
	const dateTimeString = creationTime
		? `${new Date(creationTime).toLocaleTimeString()}, ${new Date(
				creationTime,
			).toLocaleDateString()}`
		: NOT_AVAILABLE;

	return `Created at ${dateTimeString} by ${createdBy}.`;
};
