import type { UseFormReturn } from "react-hook-form";
import type {
	ClosestDriversRecommendation,
	RandomDriversRecommendation,
} from "./api/JobApi";
import type { Leg } from "./forms/LegForm";

export const GLOBALS = {
	legForm: undefined as UseFormReturn<Leg> | undefined,
	recommendationsClosestDrivers: [] as ClosestDriversRecommendation[],
	recommendationsRandomDrivers: [] as RandomDriversRecommendation[],
};
