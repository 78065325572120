import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import { authenticationApi } from "../../../../common/utils/src/lib/axios.utils";
import { buildQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";

export const fetchBusinessUnits = async (params: CelerumQueryParams) => {
	const query = buildQueryString(params);

	const response = await authenticationApi.get<CelerumResponse>(
		`/BusinessUnit/organisation${query}`,
	);
	return response.data;
};
