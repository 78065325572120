import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type { ICustomer } from "../../../../common/models/src/lib/interfaces/customer.interface";
import { jobApi } from "../../../../common/utils/src/lib/axios.utils";
import { buildQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";

export const fetchCustomers = async (params: CelerumQueryParams = {}) => {
	const { page, pageSize } = params;
	const query = buildQueryString(params);

	const response = await jobApi.get<CelerumResponse>(`/Customer${query}`);
	return {
		...response.data,
		page,
		pageSize,
	};
};

export const createCustomer = async (customer: ICustomer) => {
	const response = await jobApi.post<ICustomer>("/Customer", customer);
	return response.data;
};

export const updateCustomer = async (customer: ICustomer) => {
	const response = await jobApi.put<ICustomer>("/Customer", customer);
	return response.data;
};

export const deleteCustomer = async (id: number) => {
	await jobApi.delete<number>(`/Customer/${id}`);
	return id;
};

export const syncWithSage = async () => {
	const response = await jobApi.get("/Customer/sync");
	return response.data;
};
