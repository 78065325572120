import { useMemo } from "react";
import type { LoadGridObject } from "../Load";
import {
	type GenericGridProps,
	type LoadStatus,
	type TypedGridColumnProps,
	loadStatusNamesAndColors,
	toCurrency,
} from "../helpers";
import { toCell } from "../helpersReact";
import { GenericDataGrid } from "./GenericDataGrid";

const defaultColumns: TypedGridColumnProps<LoadGridObject>[] = [
	{
		field: "statusString",
		title: "Status",
		cell: ({ dataItem }) => {
			const statusWithColors =
				loadStatusNamesAndColors[dataItem.status as LoadStatus];
			const backgroundColor = statusWithColors?.backgroundColor ?? "gray";
			const color = statusWithColors?.color ?? "black";
			return (
				<td title={dataItem.statusString} style={{ textAlign: "center" }}>
					<span
						style={{
							backgroundColor,
							color,
							borderRadius: "15px",
							padding: "5px 20px",
						}}
					>
						{dataItem.statusString}
					</span>
				</td>
			);
		},
	},
	{ field: "uniqueId", title: "ID" },
	{ field: "loadTypeString", title: "Type" },
	{ field: "startLocationString", title: "Start Location" },
	{ field: "startDateString", title: "Start Date" },
	{ field: "endLocationString", title: "End Location" },
	{ field: "endDateString", title: "End Date" },
	{ field: "trailerString", title: "Trailer" },
	{ field: "truckString", title: "Truck" },
	{ field: "assignedToString", title: "Assigned To" },
	{ field: "driverString", title: "Driver" },
	{
		field: "revenue",
		title: "Revenue",
		cell: ({ dataItem }) =>
			toCell(
				dataItem.sharedCost
					? toCurrency(dataItem.revenue, dataItem.currencyCode)
					: "",
			),
	},
	{
		field: "sharedCost",
		title: "Shared Cost",
		cell: ({ dataItem }) =>
			toCell(
				dataItem.sharedCost
					? toCurrency(dataItem.sharedCost, dataItem.currencyCode)
					: "",
			),
	},
	{
		field: "cost",
		title: "Cost",
		cell: ({ dataItem }) =>
			toCell(
				dataItem.cost ? toCurrency(dataItem.cost, dataItem.currencyCode) : "",
			),
	},
	{
		field: "profit",
		title: "Profit",
		cell: ({ dataItem }) => toCell(dataItem.profitString),
	},
	{
		field: "currencyCode",
		title: "Currency",
		hidden: true,
	},
	{ field: "subcontractorName", title: "Subcontractor" },
	{ field: "notes", title: "Notes", hidden: true },
];

export const LoadsGrid = ({
	extraColumns = [],
	...rest
}: GenericGridProps<LoadGridObject>) => {
	const columns = useMemo(
		() => [...defaultColumns, ...extraColumns],
		[extraColumns],
	);
	return <GenericDataGrid defaultColumns={columns} name="Loads" {...rest} />;
};
