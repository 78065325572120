import { useCallback, useState } from "react";
import { LocationFormWithDTO } from "../forms/LocationFormWithDTO";
import { useDialog } from "../helpersReact";

export const useLocationFormDialog = () => {
	const [callback, setCallback] = useState<(value: number) => void>();
	const [toggleLocationFormDialog, locationFormDialog] = useDialog(
		<LocationFormWithDTO
			onSubmit={(x) => {
				x.id && callback?.(x.id);
				toggleLocationFormDialog(false);
			}}
		/>,
		"Create Location",
	);
	const showLocationForm = useCallback(
		(cb: (value: number) => void) => {
			setCallback(() => cb);
			toggleLocationFormDialog();
		},
		[toggleLocationFormDialog],
	);
	return [showLocationForm, locationFormDialog] as const;
};
